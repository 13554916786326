import React from 'react';

import cl from './ThreeColumns.module.css';

export default function ThreeColumns() {
  return (
    <div className={cl.threeColumnsDiv}>
      <div className={cl.oneOfThreeColumnsDiv}>
        <div className={cl.header}>
          <h3> Установка</h3>
          <h4> Монтаж, пусконаладка</h4>
        </div>
        <div className={cl.list}>
          <p>
            Мы работаем со всеми типами объектов:
          </p>
          <ul>
            <li>Коммерческая недвижимость</li>
            <li>Производственные помещения</li>
            <li>Частная собственность</li>
          </ul>
        </div>
        <div className={cl.therestpart}>
          <p>
            Монтажные работы, подключение и настройку оборудования производим под ключ,
            в случае с охранной, тревожной или пожарной сигнализацией -
            производим подключение на ПЦН охранного предприятия.
            Имеем опыт работы с оборудованием от разных производителей.
            С нашим предприятием-партнером вы можете заключить договор на пультовую охрану
          </p>
        </div>

      </div>
      <div className={cl.oneOfThreeColumnsDiv}>
        <div className={cl.header}>
          <h3> Обслуживание</h3>
          <h4> Диагностика, ремонт, обслуживание</h4>
        </div>
        <div className={cl.list}>
          <p>
            Мы выполняем:
          </p>
          <ul>
            <li>Регламентное обслуживание</li>
            <li>Ремонтные работы</li>
            <li>Замена старых систем</li>
            <li>Улучшение систем</li>

          </ul>
        </div>
        <div className={cl.therestpart}>
          <p>
            ВСЕ системы безопасности требуют своевременного обслуживания для бесперебойной работы.
            Обслуживание пожарной сигнализации обязательно
            в коммерческих и производственных помещениях.
          </p>
        </div>

      </div>
      <div className={cl.oneOfThreeColumnsDiv}>
        <div className={cl.header}>
          <h3>Оборудование</h3>
          <h4>В наличии и под заказ</h4>
        </div>
        <div className={cl.therestpart}>
          <p>
            У нас вы можете заказать оборудование и запасные части,
            а также расходные материалы и инструменты для электромонтажных работ
            дешевле чем в розничных магазинах.
          </p>
        </div>

      </div>
    </div>
  );
}
