/* eslint-disable no-unused-vars */
import React, { useContext } from 'react';
import { MainContext } from '../../../../context/Main.context';
import Benefits from '../Benefits/Benefits';
import cl from './UnderHeader.module.css';

export default function UnderHeader() {
  const {
    open, open2, open3, open4,
  } = useContext(MainContext);
  return (
    <div className={cl.UnderHeader}>
      <div className={open || open2 || open3 || open4 ? `${cl.blur}` : `${cl.invisible} `}>
        <h2>Проектно-монтажная организация</h2>
        <h1>СТАНДАРТ БЕЗОПАСНОСТИ</h1>
      </div>
      <h2 className={open || open2 || open3 || open4 ? `${cl.invisible}` : null}>Проектно-монтажная организация</h2>
      <h1 className={open || open2 || open3 || open4 ? `${cl.invisible}` : null}>СТАНДАРТ БЕЗОПАСНОСТИ</h1>
    </div>
  );
}
