import { useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { showToast } from '../../lib/toastify';
import cl from './Feedback.module.css';
import consultLogo from './svg/consult.svg';

export default function FeedBack() {
  const defaultValue1 = {
    name: '', phone: '', email: '',
  };
  const BOT = '5902675497:AAGOd3epHnoXAN1of9VWD30XhNVd7C0S-E4';

  const CHAT_ID = '-781052386';

  const [dataLogin, setDataLogin] = useState(defaultValue1);

  const br = '%0A';

  const msg = `Новая заявка с сайта www.sbkomi.ru:${br}${br}Тип заявки:${br}Обратная связь${br}${br}Имя пользователя:${br}${dataLogin.name}${br}${br}Телефон пользователя:${br}${dataLogin.phone}${br}${br}Email пользователя:${br}${dataLogin.email}${br}${br}`;

  const url = `https://api.telegram.org/bot${BOT}/sendMessage?chat_id=${CHAT_ID}&parse_mode=HTML&text=${msg}`;

  const handleInput = (e) => {
    setDataLogin({ ...dataLogin, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    fetch(url, {
      method: 'POST',
    });
    e.target.reset();
    showToast({ message: 'Спасибо за обращение, мы скоро свяжемся с вами!', type: 'success' });
  };
  console.log(dataLogin);
  return (
    <div id="callbackform" className={cl.feedBackBox}>
      <div className={cl.upperFBDiv}>
        <h2> ОСТАЛИСЬ ВОПРОСЫ? </h2>
      </div>

      <div className={cl.lowerFBDiv}>
        <form className={cl.feedBackForm} onSubmit={handleSubmit}>
          <div className={cl.feedBackForm_img}>
            <img src={consultLogo} className={cl.consultLogo} alt="Консультация" />
          </div>
          <div className={cl.feedBackForm_input}>
            <input type="text" name="name" placeholder="Как к вам обращаться?" onChange={handleInput} required />
          </div>
          <div className={cl.feedBackForm_input}>
            <input type="phone" name="phone" placeholder="Контактный телефон" onChange={handleInput} required />
          </div>
          <div className={cl.feedBackForm_input}>
            <input type="email" name="email" placeholder="E-mail" onChange={handleInput} />
          </div>
          <div className={cl.feedBackForm_btn}>
            <button type="submit"> Бесплатная консультация</button>
          </div>
          <ToastContainer />
        </form>
      </div>
    </div>
  );
}
