/* eslint-disable no-shadow */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, {
  useEffect, useState, useMemo, useContext,
} from 'react';

export const UserAccountContext = React.createContext();

export default function UserAccountContextProvider({ children }) {
  const [openULK, setOpenULK] = useState(false);

  const value = useMemo(() => ({
    openULK, setOpenULK,
  }));

  return (
    <UserAccountContext.Provider value={value}>
      {children}
    </UserAccountContext.Provider>
  );
}
