import React from 'react';
import { Helmet } from 'react-helmet';
import AboutAlarmSecuritySystems from './components/AboutAlarmSecuritySystems';
import ServicesAndPricesFS from './components/ServicesAndPricesFS';
import UnderHeaderAlarmSecurity from './components/UnderHeaderAlarmSecurity';
import cl from './AlarmSecurity.module.css';
import PartnershipStages from '../Security/components/PartnershipStages';
import InformationAlarm from './components/InformationAlarm';
// import Cooperation from './components/СooperationAlarm';

export default function FireSecurity() {
  return (
    <div id="alarmpage" className={cl.fireSecurityComp}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>СТАНДАРТ БЕЗОПАСНОСТИ Тревожная кнопка</title>
        <meta name="keywords" content="Стандарт безопасности, стандарт безопасности сыктывкар, установка тревожной сигнализации, тревожная кнопка, тревожная сигнализация, кнопка ГБР, ЧОП, охрана, ..." />
        <meta
          name="description"
          content="Установка и обслуживание тревожной кнопки от 4990 руб"
        />
      </Helmet>
      <UnderHeaderAlarmSecurity />
      {/* <Cooperation /> */}
      <AboutAlarmSecuritySystems />
      <InformationAlarm />
      <ServicesAndPricesFS />
      <PartnershipStages />

    </div>
  );
}
