import React from 'react';
import cl from './Licenses.module.css';
import license1 from './img/sbkomiLicense_1.jpeg';
import license2 from './img/sbkomiLicense_2.jpeg';
// import doc from './docs/egrul_sbkomi.pdf';

export default function Licenses() {
  const downloadDoc = () => {
    // using Java Script method to get PDF file
    fetch('egrul_sbkomi.pdf').then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        const alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = 'egrul_sbkomi.pdf';
        alink.click();
      });
    });
  };
  return (
    <div id="licenses" className={cl.licensesBox}>
      <h2>ЛИЦЕНЗИИ</h2>
      <div className={cl.licenseDiv}>
        <div className={cl.licenseListLeftPart}>
          <div className={cl.licenseList}>
            <img src={license1} className={cl.oneLicense} alt="Лицензия ООО Стандарт Безопасности" />
            <img src={license2} className={cl.oneLicense} alt="Лицензия ООО Стандарт Безопасности" />
          </div>
          <div className={cl.infoButtonsList}>
            <button
              type="button"
              className={cl.licenseButton}
              onClick={(e) => {
                e.preventDefault();
                window.open('https://sbis.ru/contragents/1101038852/110101001');
              }}
            >
              СБИС

            </button>
            <button
              type="button"
              className={cl.licenseButton}
              onClick={(e) => {
                e.preventDefault();
                window.open('https://www.rusprofile.ru/id/3491151');
              }}
            >
              RusProfile

            </button>

            <button className={cl.licenseButton} type="button" onClick={downloadDoc}>Скачать выписку из ЕГРЮЛ</button>
          </div>
        </div>
        <div className={cl.licenseListRightPart}>
          {/* <h3> Требования</h3>
          <h4> Крутизна </h4> */}
          <p>
            Лицензия МЧС на монтаж пожарной сигнализации необходима абсолютно всем организациям,
            которые занимаются установкой и настройкой охранно-пожарного оборудования.
            Помимо права на установку документ наделяет лицензиата правом на проведение
            технического обслуживания и восстановления уже монтированных антипожарных приборов.
            Перечень работ и услуг, которые вправе оказывать лицензиат,
            указан в Приложении к Положению
            о лицензировании противопожарной деятельности.
            Данный список был утвержден ПП РФ №1219 от 6 октября 2017 года.
          </p>
        </div>
      </div>

    </div>
  );
}
