import React from 'react';
// import AboutVideo from './components/AboutVideo';
import { Helmet } from 'react-helmet';
import AnalogOrIp from './components/AnalogOrIp';
import ServicesAndPricesVideo from './components/ServicesAndPricesVideo';
import UnderHeaderVideo from './components/UnderHeaderVideo';
import cl from './StyleVideo.module.css';

export default function Video() {
  return (
    <div id="videopage" className={cl.videoBox}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>СТАНДАРТ БЕЗОПАСНОСТИ Видеонаблюдение</title>
        <meta name="keywords" content="Стандарт безопасности, стандарт безопасности сыктывкар, установка видеонаблюдения, видеокамера, камеры видеонаблюдения, ..." />
        <meta
          name="description"
          content="Установка и обслуживание систем цифрового и аналогового видеонаблюдения"
        />
      </Helmet>
      <UnderHeaderVideo />
      {/* <AboutVideo /> */}
      <AnalogOrIp />
      <ServicesAndPricesVideo />
    </div>
  );
}
