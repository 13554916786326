/* eslint-disable max-len */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-curly-brace-presence */
import React, { useContext, useState } from 'react';
// import { Link } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import cl from './FlyoutNavBar.module.css';
import user from '../../../../Main/Main/Benefits/img/skudSbkomi.png';
import globeMenuIcon from './svg/globeMenuIcon.svg';
import licenseMenuIcon from './svg/licenseMenuIcon.svg';
import activitiesMenuIcon from './svg/activitiesMenuIcon.svg';
import aboutUsMenuIcon from './svg/aboutUsMenuIcon.svg';
import moreAboutUs from './svg/moreAboutUsMenuIcon.svg';
import DropdownItem from './DropdownItem';
import { MainContext } from '../../../../../context/Main.context';

export default function FlyoutNavBar() {
  const { open, setOpen } = useContext(MainContext);
  return (
    <div className={open ? `${cl.dropdownMenu} ${cl.active}` : `${cl.dropdownMenu} ${cl.inactive}`}>
      <h3 className={cl.upperH3}>
        ООО СТАНДАРТ БЕЗОПАСНОСТИ
        <br />
        <span>Проектно-монтажная организация</span>
      </h3>
      <ul className={cl.ulList}>

        {/* У Андрея */}
        {/* <Link to="/#company"><DropdownItem img={aboutUsMenuIcon} text={'О компании'} /></Link>
        <Link to="/#specialisation"><DropdownItem img={activitiesMenuIcon} text={'Деятельность'} /></Link>
        <Link to="/#licenses"><DropdownItem img={licenseMenuIcon} text={'Лицензии'} /></Link>
        <Link to="/#coverage"><DropdownItem img={globeMenuIcon} text={'Где мы работаем?'} /></Link>
        <Link to="/#callbackform"><DropdownItem img={moreAboutUs} text={'Узнать больше'} /></Link> */}

        <Link to="/#company" onClick={() => { setOpen(false); }}><DropdownItem img={aboutUsMenuIcon} text={'О компании'} /></Link>
        <Link to="/#specialisation" onClick={() => { setOpen(false); }}><DropdownItem img={activitiesMenuIcon} text={'Деятельность'} /></Link>
        <Link to="/#licenses" onClick={() => { setOpen(false); }}><DropdownItem img={licenseMenuIcon} text={'Лицензии'} /></Link>
        <Link to="/#coverage" onClick={() => { setOpen(false); }}><DropdownItem img={globeMenuIcon} text={'Где мы работаем?'} /></Link>
        <Link to="/#callbackform" onClick={() => { setOpen(false); }}><DropdownItem img={moreAboutUs} text={'Узнать больше'} /></Link>

      </ul>
    </div>
  );
}
