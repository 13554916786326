/* eslint-disable max-len */
import React, { useContext, useState } from 'react';
import { MainContext } from '../../../../context/Main.context';
import cl from './Map.module.css';
import './MapToggle.css';
// import map from './map.svg';

const goSyktyvkar = [{ name: 'Сыктывкар', count: 410, time: '1 час' }, { name: 'Эжва', count: 52, time: '2 часа' }, { name: 'пгт. Краснозатонский', count: 8, time: '5 часов' }, { name: 'Верхняя Максаковка', count: 4, time: '5 часов' }];
const priluzCities = [{ name: 'Объячево', count: 9, time: '4 часа' }, { name: 'Летка', count: 5, time: '4 часа' }];
const sysolsCities = [{ name: 'Визинга', count: 7, time: '6 часов' }];
const kortkerosCities = [{ name: 'Корткерос', count: 6, time: '1 день' }];
const goVorkuta = [{ name: 'Воркута', count: 54, time: '6 часов' }, { name: 'Воргашор', count: 3, time: '1 день' }, { name: 'пгт. Северный', count: 2, time: '1 день' }, { name: 'пгт. Заполярный', count: 1, time: '1 день' }];
const goVyktyl = [{ name: 'Вуктыл', count: 5, time: '1 день' }];
const goInta = [{ name: 'Инта', count: 74, time: '4 часа' }, { name: 'п.Юсьтыдор', count: 2, time: '4 часа' }, { name: 'Абезь', count: 4, time: '4 часа' }];
const goUsinsk = [{ name: 'Усинск', count: 65, time: '4 часа' }];
const pechorskiCities = [{ name: 'Печора', count: 42, time: '4 часа' }, { name: 'пгт. Кожва', count: 3, time: '1 день' }, { name: 'п. Каджером', count: 2, time: '1 день' }];
const ustcilmaCities = [{ name: 'Усть-Цильма', count: 4, time: 'по запросу' }];
const izhemskyCities = [{ name: 'Ижма', count: 6, time: 'по запросу' }, { name: 'п. Щельяюр', count: 2, time: 'по запросу' }];
const goUkhta = [{ name: 'Ухта', count: 106, time: '2 часа' }, { name: 'пгт. Водный', count: 6, time: '5 часов' }, { name: 'пгт. Ярега', count: 6, time: '5 часов' }, { name: 'пгт. Шудаяг', count: 5, time: '5 часов' }];
const sosnogorskiCities = [{ name: 'Сосногорск', count: 23, time: '4 часа' }, { name: 'пгт. Нижний Одес', count: 4, time: '4 часа' }];
const knyazhpogostskiCities = [{ name: 'Емва', count: 10, time: '1 день' }];
const syktyvdinskiCities = [{ name: 'Выльгорт', count: 15, time: '3 часа' }, { name: 'Зеленец', count: 4, time: '6 часов' }];
const udorskyCities = [{ name: 'с. Кослан', count: 2, time: 'по запросу' }];
const troitskopecherskiCities = [{ name: 'пгт. Троицо-Печорск', count: 4, time: 'по запросу' }];
const ustvymskyCities = [{ name: 'Микунь', count: 10, time: '1 день' }, { name: 'с. Айкино', count: 6, time: '1 день' }, { name: 'пгт. Жешарт', count: 23, time: '1 день' }];
const koygorodskiCities = [{ name: 'c.Койгородок', count: 2, time: 'по запросу' }];
const ustkulomskiCities = [{ name: 'c.Усть-Кулом', count: 4, time: 'по запросу' }];

export default function () {
  const [place, setPlace] = useState();
  const [citiesArr, setCitiesArr] = useState([]);
  const [cityBg, setCityBg] = useState('mapdiv');
  const [showPrice, setShowPrice] = useState(false);
  const { setVisibleModalAsk, setCity1 } = useContext(MainContext);

  const handleToggleChange = () => {
    setShowPrice(!showPrice);
  };

  function padezhObjecta(num) {
    const lastNum = Number(num.toString().split('').pop());
    switch (lastNum) {
      case 1:
        return 'объект';
      case 2:
        return 'объекта';
      case 3:
        return 'объекта';
      case 4:
        return 'объекта';
      case 5:
        return 'объектов';
      case 6:
        return 'объектов';
      case 7:
        return 'объектов';
      case 8:
        return 'объектов';
      case 9:
        return 'объектов';
      case 0:
        return 'объектов';
      default: return 'об.';
    }
  }
  function setMapSettings(e) {
    switch (e.target.id) {
      case 'priluzki':
        return (setCityBg('mapdivPriluz'), setPlace('Прилузский район'), setCitiesArr(priluzCities), setCity1('Прилузский район'));
      case 'sysolski':
        return (setCityBg('mapdivSysols'), setPlace('Сысольский район'), setCitiesArr(sysolsCities), setCity1('Сысольский район'));
      case 'kortkeroski':
        return (setCityBg('mapdivKortkeros'), setPlace('Корткеросский район'), setCitiesArr(kortkerosCities), setCity1('Корткеросский район'));
      case 'go_syktyvkar':
        return (setCityBg('mapdivSyk'), setPlace('Городской округ Сыктывкар'), setCitiesArr(goSyktyvkar), setCity1('Городской округ Сыктывкар'));
      case 'go_vorkuta':
        return (setCityBg('mapdivVorkuta'), setPlace('Городской округ Воркута'), setCitiesArr(goVorkuta), setCity1('Городской округ Воркута'));
      case 'go_vyktyl':
        return (setCityBg('mapdivVyktyl'), setPlace('Городской округ Вуктыл'), setCitiesArr(goVyktyl), setCity1('Городской округ Вуктыл'));
      case 'go_inta':
        return (setCityBg('mapdivInta'), setPlace('Городской округ Инта'), setCitiesArr(goInta), setCity1('Городской округ Инта'));
      case 'go_usinsk':
        return (setCityBg('mapdivUsinsk'), setPlace('Городской округ Усинск'), setCitiesArr(goUsinsk), setCity1('Городской округ Усинск'));
      case 'pechorski':
        return (setCityBg('mapdivPechora'), setPlace('Печорский район'), setCitiesArr(pechorskiCities), setCity1('Печорский район'));
      case 'usttsilemsky':
        return (setCityBg('mapdivUstcilma'), setPlace('Усть-Цилемский район'), setCitiesArr(ustcilmaCities), setCity1('Усть-Цилемский район'));
      case 'izhemsky':
        return (setCityBg('mapdivIzhma'), setPlace('Ижемский район'), setCitiesArr(izhemskyCities), setCity1('Ижемский район'));
      case 'go_ukhta':
        return (setCityBg('mapdivUkhta'), setPlace('Городской округ Ухта'), setCitiesArr(goUkhta), setCity1('Городской округ Ухта'));
      case 'sosnogorski':
        return (setCityBg('mapdivSosnogorsk'), setPlace('Сосногорский район'), setCitiesArr(sosnogorskiCities), setCity1('Сосногорский район'));
      case 'knyazhpogostski':
        return (setCityBg('mapdivEmva'), setPlace('Княжпогостский район'), setCitiesArr(knyazhpogostskiCities), setCity1('Княжпогостский район'));
      case 'syktyvdinski':
        return (setCityBg('mapdivSyktyvdin'), setPlace('Сыктывдинский район'), setCitiesArr(syktyvdinskiCities), setCity1('Сыктывдинский район'));
      case 'udorsky':
        return (setCityBg('mapdivUdorsky'), setPlace('Удорский район'), setCitiesArr(udorskyCities), setCity1('Удорский район'));
      case 'troitskopecherski':
        return (setCityBg('mapdivTroitskopecherski'), setPlace('Троицко-Печорский район'), setCitiesArr(troitskopecherskiCities), setCity1('Троицко-Печорский район'));
      case 'ustvymsky':
        return (setCityBg('mapdivUstvymsky'), setPlace('Усть-Вымский район'), setCitiesArr(ustvymskyCities), setCity1('Усть-Вымский район'));
      case 'koygorodski':
        return (setCityBg('mapdivKoygorodski'), setPlace('Койгородский район'), setCitiesArr(koygorodskiCities), setCity1('Койгородский район'));
      case 'ustkulomski':
        return (setCityBg('mapdivUstkulomski'), setPlace('Усть-Куломский район'), setCitiesArr(ustkulomskiCities), setCity1('Усть-Куломский район'));

      default: return '';
    }
  }
  console.log(cityBg);
  return (
    <>
      <h2 id="coverage" className={cl.mapH2}>ГОРОДА</h2>
      <div className={cl[cityBg]}>
        {/* <img src={map} className={cl.svgmap} alt="fff" /> */}
        <div className={cl.leftAndRight}>
          <div className={cl.leftPart}>
            <svg xmlns="http://www.w3.org/2000/svg" className={cl.svgmap} viewBox="0 0 929.33331 870.66669">
              {/* <g fill="#61DAFB"> */}
              <path className={place === 'Прилузский район' ? cl.partChosen : cl.part} onClick={(e) => setMapSettings(e)} id="priluzki" d="m 215.44639,851.27599 14.45068,3.94109 2.62739,-11.82327 -1.3137,-26.27396 6.56849,-2.62739 h 3.94109 l 2.6274,-2.6274 -5.25479,-5.25479 1.3137,-5.25479 v -18.39176 l 5.25479,-23.64656 15.76437,-7.88218 5.25479,-7.88219 -6.56849,-5.25479 -14.45067,-7.88218 -6.56849,-13.13698 -11.82328,-2.62739 -14.45067,-24.96025 -9.19589,-9.19589 -27.58764,10.50958 3.94109,7.88219 2.62739,7.88218 -2.62739,24.96026 -1.3137,3.94109 9.19588,13.13697 -15.76437,15.76437 1.3137,31.52874 -3.94109,9.19589 26.27395,9.19588 v 13.13697 l 24.96025,14.45068 z" />
              <path className={place === 'Сысольский район' ? cl.partChosen : cl.part} onClick={(e) => setMapSettings(e)} id="sysolski" d="m 205.52199,680.18959 15.87199,-2.71316 7.18988,-24.96108 12.75186,3.66276 8.13948,-10.03869 16.95726,18.85647 0.27132,11.93791 11.80225,5.42632 11.12396,14.37976 -0.40697,6.7829 -0.13566,1.22093 -8.27514,1.08526 -4.47672,8.4108 -15.46502,-1.6279 4.2054,11.80226 -3.93408,2.71316 -11.25962,-6.24027 -5.29067,5.42632 -5.29066,-10.17436 -11.80226,-2.17053 -14.37975,-24.96108 -8.13949,-8.13949 z" />
              <path className={place === 'Корткеросский район' ? cl.partChosen : cl.part} onClick={(e) => setMapSettings(e)} id="kortkeroski" d="m 316.57731,706.07098 11.33633,8.95709 21.55301,-0.41986 1.5395,-10.77651 17.07446,1.5395 2.51918,-10.7765 -4.19864,-10.4966 4.6185,-11.05641 -10.07673,-13.43564 0.13996,-9.51691 15.25505,0.2799 0.41986,-12.87582 -9.79682,-22.25278 24.21215,-10.07673 2.93904,-7.4176 4.3386,-15.67491 0.13995,-13.99546 6.57787,-9.93678 -7.6975,-5.03837 0.2799,-14.13541 -11.05641,-3.77877 -4.89841,3.35891 -14.27537,-4.75846 -11.61623,17.77423 -18.33406,-8.95709 -7.97741,7.13768 -9.37696,-4.05868 -19.03382,46.04507 5.17832,2.09931 -6.43791,10.4966 9.237,12.316 0.83973,9.09705 8.39727,15.25506 -10.07673,10.21668 v 7.55755 l 6.01805,13.15573 z" />
              <path className={place === 'Городской округ Сыктывкар' ? cl.partChosen : cl.part} onClick={(e) => setMapSettings(e)} id="go_syktyvkar" d="m 274.54831,625.08766 0.49026,5.88318 20.10086,14.95308 10.29556,9.56016 1.22566,-4.65751 10.05043,-9.8053 -7.84423,-13.72741 -0.49027,-3.18673 -16.42387,-7.35397 -1.96106,-3.18672 -5.39291,1.22566 0.49026,4.65752 z" />
              <path className={place === 'Сыктывдинский район' ? cl.partChosen : cl.part} onClick={(e) => setMapSettings(e)} id="syktyvdinski" d="m 314.25976,704.0203 -23.28758,-6.86371 -11.27609,-15.68847 -11.76636,-5.14778 v -12.50175 l -18.38493,-19.6106 -8.57963,10.05043 -12.01149,-2.94159 1.47079,-7.10884 -6.37344,-4.16725 2.20619,-10.78583 14.95308,-6.37344 12.25662,-10.05043 15.44334,-7.84423 12.25662,-3.43186 5.88318,-0.49026 1.71593,-14.21768 15.68847,7.35397 -5.63804,10.29556 9.31503,13.23715 v 4.65752 l -15.44334,-6.61858 -2.45133,-3.43185 -6.37344,1.96106 0.24513,4.16725 -10.78583,6.12831 0.7354,7.35397 30.39642,24.26811 4.16725,6.86371 4.41239,8.82476 1.22566,14.46282 z" />
              <path className={place === 'Койгородский район' ? cl.partChosen : cl.part} onClick={(e) => setMapSettings(e)} id="koygorodski" d="m 245.50145,810.55723 -5.232,-4.62831 1.20738,-4.02461 0.20123,-18.51322 5.43323,-23.14153 15.29353,-7.44554 5.83569,-9.8603 -7.64676,-5.43323 -14.48861,-7.848 -1.00616,-2.81723 4.62831,-4.6283 12.07384,6.43938 4.62831,-3.42092 -4.02462,-11.47015 14.68984,1.60984 4.42708,-8.45169 8.25046,-1.40861 1.20738,-7.848 24.75138,8.45169 11.47015,9.65907 -4.42708,19.92184 16.50092,7.04308 -2.41477,3.21969 11.67138,9.05538 -2.01231,6.43938 -4.82953,5.83569 -8.85415,-4.22584 -28.77599,32.39814 -12.27508,-13.28123 -12.07384,17.30584 -23.14153,1.40862 z" />
              <path className={place === 'Усть-Куломский район' ? cl.partChosen : cl.part} onClick={(e) => setMapSettings(e)} id="ustkulomski" d="m 399.04046,539.09703 -0.20123,13.07999 8.04923,5.63446 -7.2443,10.86646 0.20123,13.48245 -4.42708,15.29354 -3.01846,8.25045 -24.34891,10.06154 9.8603,21.33045 -0.60369,14.28738 -15.29353,-0.20123 -0.20123,8.25046 10.26276,13.48246 -4.62831,11.26892 4.42708,10.26276 19.51938,3.62215 -2.01231,13.68369 21.73292,5.63446 0.20123,-11.67138 49.90521,7.64677 -1.00616,-10.86646 9.45785,-0.80492 2.81723,-12.87877 17.50707,6.23815 7.84799,-13.68368 19.11692,2.21353 2.21354,-9.65907 -4.42708,-3.62215 4.02462,-7.44554 2.21353,-14.89107 -6.64061,-4.22584 5.232,-10.86646 -27.97107,-21.73292 -6.03692,3.21969 -6.64061,-4.22584 4.6283,-8.25046 -14.08614,-13.07999 12.87876,-20.3243 -17.30584,-9.65908 z" />
              <path className={place === 'Троицко-Печорский район' ? cl.partChosen : cl.part} onClick={(e) => setMapSettings(e)} id="troitskopecherski" d="m 491.95019,489.27816 -16.62596,22.86069 6.53163,6.23473 -7.42231,1.18757 -7.12541,38.00219 17.45773,10.32186 -12.98134,20.53765 13.75635,12.78758 -4.84378,8.52506 4.84378,2.90627 6.39379,-3.29377 30.03146,23.44391 -5.81254,10.46258 6.3938,4.06878 -2.90627,16.08136 5.81254,0.3875 4.84378,-8.33131 8.91256,0.96876 -0.19375,2.51877 8.71881,-0.38751 0.77501,-1.93751 39.13777,-0.3875 v -1.35626 l 31.19397,-0.19375 2.32501,-5.03754 8.13756,0.58126 5.03753,-9.88132 5.42504,-1.35626 2.13127,-10.26882 -5.03754,-3.68128 6.3938,-13.17509 0.58125,-33.51898 2.13127,-0.58126 -0.58126,-9.88132 2.13127,-0.96875 v -9.10632 h -2.71252 l -2.32502,1.93752 -2.90627,-1.93752 0.3875,-5.42504 -2.13126,-0.775 0.19375,-4.84378 -2.90627,-0.77501 0.96876,-5.81254 2.13126,-0.19375 -0.96875,-12.98134 -11.43133,-8.52506 v -24.21892 l -28.09395,-6.39379 -5.61879,-20.15014 -6.39379,-2.32502 -7.36255,2.51877 -2.32502,4.84378 -5.61879,32.74398 h -23.83142 l -19.56888,-5.23129 -5.61879,5.42504 -11.81883,-1.93751 -1.16251,-8.33131 z" />
              <path className={place === 'Усть-Вымский район' ? cl.partChosen : cl.part} onClick={(e) => setMapSettings(e)} id="ustvymsky" d="m 242.77399,540.73928 -14.55967,56.20709 -7.44913,4.40176 -11.1737,34.53689 12.52809,3.72456 2.70878,-12.18949 15.57545,-6.09474 10.83511,-9.14212 13.54387,-7.11053 9.14212,-3.72457 10.4965,-1.69298 h 0.6772 l 2.03158,-13.20528 -20.99301,-14.89827 -6.43334,-7.44913 4.40176,-17.60704 -1.01579,-4.40176 -7.11054,-3.38597 h -7.11053 z" />
              <path className={place === 'Княжпогостский район' ? cl.partChosen : cl.part} onClick={(e) => setMapSettings(e)} id="knyazhpogostski" d="m 300.4386,590.88042 -14.99516,-7.14055 -18.74394,-13.21001 -5.89095,-6.78352 4.46284,-16.95881 -1.42811,-5.1769 -7.14055,-3.57027 -2.49919,-3.74879 13.92407,-32.4895 20.70759,4.64136 0.17852,-16.24475 -8.92569,-16.78029 -13.92407,-11.7819 0.17851,-28.20517 27.13409,7.8546 2.67771,-4.10581 -1.60663,-10.88934 7.85461,-6.96204 2.6777,-13.21001 3.74879,-14.2811 4.81987,5.35541 10.53231,-11.60339 6.96204,1.42811 2.6777,2.67771 13.38853,0.35702 -7.67609,13.56705 8.56866,3.03473 -5.1769,14.10258 9.99677,9.99677 -0.35703,9.99677 10.17528,1.42811 -0.17851,53.55412 19.10097,5.53392 -3.03473,9.81826 7.31906,11.7819 v 11.06785 l -4.28433,3.03474 -14.45961,-4.64136 -11.60339,17.67286 -17.85138,-8.56866 -8.21163,7.14055 -9.63974,-4.28433 z" />
              <path className={place === 'Удорский район' ? cl.partChosen : cl.part} onClick={(e) => setMapSettings(e)} id="udorsky" d="m 308.11469,390.76654 -1.42811,-5.89095 -1.2496,-14.45962 -13.38853,-2.32067 -5.35541,1.07108 -4.99839,17.49434 -26.77705,-8.39014 -2.14217,1.60662 -5.1769,10.3538 -14.10258,-2.67771 -2.14217,1.78514 -2.6777,11.60339 -18.38691,-3.74879 -1.42811,1.42811 -1.60663,9.99677 -11.24636,-3.39176 v -10.88934 l -1.78514,-0.89257 -61.23021,-2.49919 -6.605,2.49919 -16.24475,-3.74878 -21.064622,7.53134 2.320678,3.89353 9.996764,12.31745 36.95235,28.91922 6.78352,15.70921 -4.28433,6.24798 0.17851,7.67609 7.8546,20.52908 13.03151,7.49758 v 1.60662 l -21.24314,21.95719 0.71406,3.74879 1.60662,1.07108 v 7.49758 l 1.60663,1.60662 -0.35703,19.99354 -1.2496,0.89256 -0.17851,8.92569 6.24798,4.46284 -0.71406,4.81987 -2.49919,0.35703 0.53554,4.99839 7.85461,0.89256 0.35702,2.14217 h 3.21325 l 2.49919,-10.3538 26.06301,1.96365 0.53554,-16.78029 7.8546,-19.45799 10.17528,2.6777 5.35542,-10.35379 31.77544,7.14055 6.42649,-1.78514 5.89095,-0.17851 h 1.07109 l -2.32068,-3.57028 14.63812,-33.20355 20.35057,4.46284 0.17851,-14.99515 -9.1042,-16.60178 -13.74555,-11.60339 v -29.9903 l 27.3126,7.8546 2.49919,-3.21325 -1.60663,-11.60339 8.03312,-6.96203 z" />
              <path className={place === 'Городской округ Ухта' ? cl.partChosen : cl.part} onClick={(e) => setMapSettings(e)} id="go_ukhta" d="m 348.23118,390.12978 -6.84724,12.71631 7.82542,3.09756 -5.05392,14.18357 9.9448,10.27087 -0.65211,9.45571 9.9448,1.46727 0.16303,53.79976 19.23749,5.70603 -3.26059,9.94481 7.33633,11.90116 -0.16303,11.08601 79.23237,23.31323 0.97818,-3.91271 -17.60719,-28.69321 4.07574,-7.66239 -7.82542,-11.5751 -28.20412,-8.64057 -1.6303,-27.22593 -14.3466,-14.02055 6.0321,-11.41207 -5.54301,-16.62902 16.79205,-16.30295 -68.96152,-22.98717 z" />
              <path className={place === 'Сосногорский район' ? cl.partChosen : cl.part} onClick={(e) => setMapSettings(e)} id="sosnogorski" d="m 419.63813,410.67151 4.07574,-2.44545 17.77023,6.52119 15.32478,-16.79205 16.30296,-2.44544 6.03209,8.47753 13.85751,-4.23876 17.77023,6.03209 -6.19513,22.33505 -11.41207,6.0321 7.17331,10.92298 -8.31451,7.33633 11.41207,9.9448 14.83569,6.84725 -4.23877,6.52118 0.16303,15.32478 11.08601,8.8036 -4.23877,4.56482 -10.43389,-1.63029 -0.97818,-7.98845 -17.77022,-6.68421 -17.44417,23.31323 -0.48909,0.81514 6.0321,5.54301 -6.68421,1.14121 -6.0321,33.25803 -16.79205,-27.71503 4.07574,-7.66239 -8.3145,-12.22722 -28.04109,-8.80359 -1.6303,-26.73685 -14.3466,-13.85752 6.03209,-11.41207 -5.543,-16.46599 z" />
              <path className={place === 'Городской округ Вуктыл' ? cl.partChosen : cl.part} onClick={(e) => setMapSettings(e)} id="go_vyktyl" d="m 532.96512,415.41096 -21.28683,-9.37256 -6.03657,22.39884 -10.96113,5.8777 6.98971,10.80228 -8.26057,7.14856 10.64342,9.37256 15.40913,7.30742 -4.60686,7.14856 0.47658,14.7737 10.80227,8.10171 1.42971,0.31771 19.38055,5.24228 h 22.8754 l 5.40114,-32.24796 2.224,-4.448 0.31771,-0.79428 7.94285,-2.54171 7.30742,2.70056 5.56,20.01598 27.16454,6.03657 5.08342,-32.08911 3.17714,-23.19312 9.53142,-17.31541 -6.672,-5.40113 -2.54171,-22.87541 -4.13028,-4.7657 2.06514,-20.01598 -20.17483,15.88569 -9.84913,0.95315 -5.24228,3.81256 -16.67999,-8.57827 -13.50284,9.84913 -16.83884,-7.62514 z" />
              <path className={place === 'Ижемский район' ? cl.partChosen : cl.part} onClick={(e) => setMapSettings(e)} id="izhemsky" d="m 350.57378,386.9034 9.47115,-16.11508 36.18826,-13.99468 -14.56012,-30.25113 0.28272,-4.66489 40.28771,-93.01514 55.13055,56.82688 -2.26176,29.54432 -2.26177,2.82721 -15.12556,9.47115 -2.12041,28.27207 -2.54448,18.09414 3.67537,23.1831 -15.40829,16.96325 -17.67005,-6.64394 -4.80625,3.10993 -67.85299,-22.61767 -0.98952,-0.28272 z" />
              <path className={place === 'Усть-Цилемский район' ? cl.partChosen : cl.part} onClick={(e) => setMapSettings(e)} id="usttsilemsky" d="m 286.67888,368.24383 5.65442,-0.98952 13.42923,2.26176 0.28273,0.14136 1.4136,14.84284 1.83768,6.64394 4.24082,4.6649 10.31931,-11.16747 7.4921,1.4136 2.68584,2.68585 13.14652,0.56544 12.43972,-19.50774 35.05737,-13.28787 0.42408,-0.28272 -14.41876,-29.40297 0.56545,-5.37169 40.42907,-93.01514 2.26177,-53.57559 -33.78514,-5.08898 -24.17263,-5.65441 -9.75386,-0.28272 -22.90039,21.91086 -20.07317,12.72243 -56.82688,35.48146 -16.53917,9.61251 -0.14136,39.72227 -1.55496,38.02595 22.33494,-4.24081 12.86379,38.02594 14.70149,5.51306 z" />
              <path className={place === 'Городской округ Усинск' ? cl.partChosen : cl.part} onClick={(e) => setMapSettings(e)} id="go_usinsk" d="m 425.60745,175.21032 -2.36719,53.1994 55.81577,57.31083 -2.51683,28.62969 -2.11801,2.99013 30.64884,-18.68831 12.08511,9.84251 16.94407,-8.8458 15.94736,6.85238 5.73108,-5.35732 0.77259,-7.57419 10.21627,-9.71793 6.57816,3.83654 5.85567,-0.37377 2.36718,-2.36719 10.59005,0.49836 2.61636,1.74424 4.73438,0.24918 6.47861,-5.60649 0.39882,-36.47879 -1.49507,-50.33386 c -0.29071,-13.40478 -0.58141,-21.11708 -0.87212,-28.78 l -22.67515,0.24917 -18.20331,0.92156 -17.05531,0.44892 -20.32333,0.69676 -18.54836,0.54913 -16.23494,0.84573 -20.02039,1.27228 z" />
              <path className={place === 'Печорский район' ? cl.partChosen : cl.part} onClick={(e) => setMapSettings(e)} id="pechorski" d="m 504.39389,300.87298 -45.18605,27.55247 -1.83683,26.81773 -2.57157,18.00095 3.3063,22.77671 15.42938,-2.2042 5.87786,8.44943 13.95992,-4.04103 18.73568,5.87786 20.20515,9.18416 17.26621,-33.43034 16.89885,7.7147 13.59255,-9.55153 16.89885,8.44943 5.87786,-3.67366 8.81679,-0.73474 20.20515,-15.42938 -4.77576,-4.77576 20.93987,-23.87881 1.46947,-4.04103 5.87786,-1.46946 -8.08206,-10.28626 -11.02099,5.51049 -4.77576,-7.34732 -11.38835,1.46946 -11.38836,-14.69465 6.24523,-10.65362 v -10.65362 l -7.34733,-3.3063 -8.08205,6.24523 -5.5105,-0.36737 -2.57156,-1.46946 -9.18416,-0.73473 -2.2042,1.83683 -6.97996,0.73473 -6.61259,-2.93893 -8.44942,8.08206 -0.73474,6.97996 -6.24522,6.97996 -16.89885,-6.24523 -17.26621,8.81679 z" />
              <path className={place === 'Городской округ Инта' ? cl.partChosen : cl.part} onClick={(e) => setMapSettings(e)} id="go_inta" d="m 605.35209,166.27097 1.9212,115.09724 8.73272,3.66774 0.17466,11.52719 -6.11291,10.47927 10.47927,13.62304 11.00322,-1.39724 4.89032,6.98618 10.65392,-5.06498 0.34931,-0.17465 9.25668,11.35253 -1.22258,1.22258 11.8765,13.44839 4.01705,-3.31843 0.17466,-7.33549 h 6.81152 l 2.09585,-4.89032 0.87327,-6.63687 9.43134,-8.20875 -2.27051,-9.08203 7.16083,-18.86267 41.04378,-38.42397 -0.69862,-12.2258 5.06498,-0.69862 -1.04793,-13.7977 8.20876,-0.87327 -39.29724,-31.61244 0.34931,-25.49954 -31.43779,-4.89033 z" />
              <path className={place === 'Городской округ Воркута' ? cl.partChosen : cl.part} onClick={(e) => setMapSettings(e)} id="go_vorkuta" d="m 677.48435,159.98342 44.71153,-39.64655 0.17465,-15.71889 11.00323,-20.609221 13.62304,0.349308 3.31843,-17.814747 -1.74654,-1.571889 v -6.112904 l 13.27373,-8.907373 -0.17465,-5.239632 4.1917,-5.58894 -1.57189,-7.16083 13.62305,-10.828572 15.36958,-5.064977 1.74654,6.811521 -0.87327,5.064977 -1.22258,14.845623 8.0341,18.862673 8.90738,-3.667742 h 12.05115 l 3.49309,2.969125 -0.69862,9.431337 3.66774,2.619815 3.14378,8.383411 -7.85945,2.619816 -4.01705,4.01705 v 2.794471 l 9.25669,-0.349309 1.57188,4.191705 0.17466,5.239632 -11.35254,10.653912 0.34931,4.36636 -12.92442,16.94148 -1.39724,2.79447 0.52397,20.60922 -10.12996,6.98617 -8.20875,6.98618 -6.46222,8.90737 -5.23963,5.06498 -10.65391,2.61981 -4.89033,7.33549 -10.47926,14.49631 -2.09585,5.06498 2.2705,3.31843 0.34931,5.93825 -0.87327,0.69862 -39.47189,-31.96175 0.17465,-25.6742 z" />
              {/* </g> */}

            </svg>
          </div>
          <div className={cl.rightPart}>
            <div className={cl.chosenPlaceDiv}>
              <h3>Район:</h3>
              {place ? (
                <>
                  <h4>{place}</h4>
                  <div className="btn_container1">
                    <i className="fa1 fa-sun-o1" aria-hidden="true" />
                    <label className="switch1 btn-color-mode-switch1">
                      <input type="checkbox" name="color_mode1" id="color_mode1" value="1" onClick={handleToggleChange} checked={showPrice} />
                      <label htmlFor="color_mode1" data-on="Цены" data-off="Инфо" className="btn-color-mode-switch-inner1" />
                    </label>
                    <i className="fa1 fa-moon-o1" aria-hidden="true" />
                  </div>
                  <div className={cl.cities}>
                    {showPrice ? (
                      <div className={cl.priceside}>
                        <button type="button" className={cl.pricesideBtn} onClick={() => { setVisibleModalAsk(true); }}>Запросить стоимость услуг</button>
                      </div>
                    ) : (
                      <div className={cl.allForInfoAboutPlace}>
                        <div className={cl.infoAboutPlaceMenu}>
                          <div className={cl.city}>Населенный пункт</div>
                          <div className={cl.kolvo}>На обслуживании</div>
                          <div className={cl.rvr}>Срочные РВР</div>
                        </div>
                        {citiesArr?.map((city) => (
                          <div className={cl.cityInfo}>
                            <div className={cl.cityName}>{city.name}</div>
                            <div className={cl.cityCount}>
                              {`${city.count} ${padezhObjecta(city.count)}`}
                            </div>
                            <div className={cl.cityTime}>{city.time}</div>
                          </div>

                        ))}
                      </div>
                    )}

                  </div>
                  {/* <button type="button" className={cl.makeRequest} id={`${place}`}>Оставить заявку</button> */}
                </>
              ) : (
                <p>Выберите район на карте</p>
              )}

            </div>
          </div>

        </div>
      </div>
    </>
  );
}
