import React from 'react';

import cl from './About.module.css';

export default function About() {
  return (
    <div className={cl.about}>
      <h2> О КОМПАНИИ </h2>

      <div id="company" className={cl.aboutLeft}>
        <p>
          {`Компания ООО "СТАНДАРТ БЕЗОПАСНОСТИ" - основана в 2003 году, и уже ${new Date().getFullYear() - 2003} лет успешно ведет свою деятельность на территории Республики Коми.`}
        </p>
        <p>
          Наша компания специализируется на проектировании и
          установке противопожарных систем, и систем обеспечения безопасности.
          <br />
          Мы предоставляем полный комплекс услуг по
          установке и техническому обслуживанию систем безопасности.
          В работе мы используем только сертифицированное отечественное или зарубежное оборудование.

        </p>
        <p>
          За всю историю нашей компании, мы реализовали более
          3500 проектов как для частных клиентов, так и для собственников бизнеса.
          <br />
          На данный момент мы обслуживаем противопожарные сисемы на более чем 500-х объектах.

        </p>
        <p>
          У нас имееются все необходимые лицензии для установки
          и обслуживания пожарной сигнализации и систем пожаротушения.
        </p>
      </div>
    </div>
  );
}
