/* eslint-disable max-len */
/* eslint-disable no-undef */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useContext, useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { showToast } from '../../../../lib/toastify';
import cl from './ModalStyle.module.css';
import { MainContext } from '../../../../context/Main.context';
// import ModalTimer from '../UI/ModalTimer/ModalTimer';
// import QuestionForm from '../QuestionForm/QuestionForm';

const initialvalue = {
  title: '',
  content: '',
  startDate: '',
  endDate: '',
  taskForUserId: '',

};
// children,
function ModalAskForPrice({ children, visibleModalAsk, setVisibleModalAsk }) {
  const { city1, setCity1 } = useContext(MainContext);

  const BOT = '5902675497:AAGOd3epHnoXAN1of9VWD30XhNVd7C0S-E4';

  const CHAT_ID = '-781052386';

  const defaultValue = {
    name: '', phone: '', email: '', cityNp: '', chosenService: '',
  };
  const [dataAsk, setDataAsk] = useState(defaultValue);

  const br = '%0A';

  const msg = `Новая заявка с сайта www.sbkomi.ru:${br}${br}Тип заявки:${br}Запрос стоимости ${dataAsk.chosenService}${br}${br}Район:${city1}${br}Населенный пункт: ${dataAsk.cityNp}${br}${br}Имя пользователя:${br}${dataAsk.name}${br}${br}Телефон пользователя:${br}${dataAsk.phone}${br}${br}Email пользователя:${br}${dataAsk.email}${br}${br}`;

  const url = `https://api.telegram.org/bot${BOT}/sendMessage?chat_id=${CHAT_ID}&parse_mode=HTML&text=${msg}`;

  const rootClasses = [cl.myModal];
  if (visibleModalAsk) {
    rootClasses.push(cl.active);
  }
  const handleInput = (e) => {
    setDataAsk({ ...dataAsk, [e.target.name]: e.target.value });
    console.log(dataAsk);
  };
  const handleSubmitAsk = (e) => {
    e.preventDefault();
    fetch(url, {
      method: 'POST',
    });
    e.target.reset();
    setTimeout(() => { setVisibleModalAsk(false); }, 1500);
    showToast({ message: 'Запрос отправлен!', type: 'success' });
    //   .finally(console.log('fdfd'), setDataService(defaultValue));
  };
  return (
    <div className={rootClasses.join(' ')} onClick={() => setVisibleModalAsk(false)}>
      <div className={cl.myModalContent} onClick={(e) => e.stopPropagation()}>

        {/* {children} */}
        <form className={cl.loginForm} onSubmit={handleSubmitAsk}>
          <h2>Узнать стоимость обслуживания</h2>
          {/* <form onSubmit={handleSubmit}> */}
          <h3>{city1}</h3>

          <label className={cl.formLabel}>Как к вам обращаться?</label>
          <input type="text" name="name" onChange={handleInput} required />
          <label className={cl.formLabel}>Телефон</label>
          <input type="phone" name="phone" onChange={handleInput} required />
          <label className={cl.formLabel}>E-mail</label>
          <input type="email" name="email" onChange={handleInput} />
          <label className={cl.formLabel}>Населенный пункт</label>
          <input type="text" name="cityNp" onChange={handleInput} />
          <label className={cl.formLabel}>Ремонт и обслуживание</label>
          <select name="chosenService" id="chosenService" className={cl.askInput} onChange={handleInput}>
            {/* <option selected disabled>Выбрать</option> */}
            <optgroup label="Обслуживание охранной сигнализации">
              <option value="Выезд техника на ремонт ОС">Выезд техника на ремонт ОС</option>
              <option value="Регулярное обслуживание ОС">Регулярное обслуживание ОС</option>
            </optgroup>
            <optgroup label="Обслуживание пожарной сигнализации">
              <option value="Ежемесячное обслуживание ПС и СОУЭ">Ежемесячное обслуживание ПС и СОУЭ</option>
              <option value="Ежеквартальное обслуживание ПС и СОУЭ">Ежеквартальное обслуживание ПС и СОУЭ</option>
            </optgroup>
            <optgroup label="Обслуживание систем видеонаблюдения">
              <option value="Выезд техника на ремонт системы видеонаблюдения">Выезд техника на ремонт системы видеонаблюдения</option>
              <option value="Обновление программного обеспечения">Обновление программного обеспечения</option>
              <option value="Договор на регулярное обслуживание">Договор на регулярное обслуживание</option>
            </optgroup>
            <optgroup label="Обслуживание СКУД">
              <option value="Выезд техника на ремонт СКУД">Выезд техника на ремонт СКУД</option>
              <option value="Обновление прав доступа">Обновление прав доступа</option>
              <option value="Договор на регулярное обслуживание">Договор на регулярное обслуживание</option>
            </optgroup>
          </select>
          <button type="submit" className={cl.loginFormButton}>Отправить</button>
          <ToastContainer />
        </form>

      </div>
    </div>
  );
}

export default ModalAskForPrice;
