import React from 'react';
import cl from './StyleAlarmSecurityComp.module.css';

export default function AboutFireSecuritySystems() {
  return (
    <div className={cl.AboutSecurityBlock}>
      {/* <div className={cl.LeftInfo}>
        <h3>
          ПРЕИМУЩЕСТВА
        </h3>
        <h4>современных охранных систем</h4>

      </div> */}

      <div className={cl.RightInfo}>
        <div className={cl.RightInfo_h3}>
          <h3>
            ПРЕИМУЩЕСТВА
          </h3>
        </div>
        <div className={cl.RightInfo_h4}>
          {/* <h4>Современных тревожных кнопок</h4> */}
        </div>
        <div className={cl.ul_li_rigth}>
          <ul className={cl.ul_or_liPlus}>
            <li>Скрытая подача сигнала тревоги</li>
            <li>Прибытие ГБР без лишнего звонка</li>
            <li>Автономная работа до 24 часов</li>
            <li>Уверенность в любых нестандартных ситуациях</li>
          </ul>
        </div>
      </div>
      <div className={cl.RightInfo}>
        <div className={cl.RightInfo_h3}>
          <h3>
            ВОЗМОЖНОСТИ
          </h3>
        </div>
        <div className={cl.RightInfo_h4}>
          {/* <h4>Современных тревожных систем</h4> */}
        </div>
        <div className={cl.ul_li_rigth}>
          <ul className={cl.ul_or_liPlus}>
            <li>Мобильная тревожная кнопка (с телефона)*</li>
            <li>Включение сирены</li>
            <li>Проверка работоспособности через телефон</li>
          </ul>
        </div>
        {/* <img src={svg026} className={cl.kop026img} alt="КОП-02.6 Стандарт Безопасности" /> */}
        {/* <svg viewBox="0 0 192.59583 148.869" className={cl.kop026img} version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g>
            <circle className={cl.svgTest} cx="75.951022" cy="53.741062" r="6.5" />
            <circle className={cl.svgTest} cx="95.999702" cy="53.741062" r="6.5" />
            <circle className={cl.svgTest} cx="115.97059" cy="53.741062" r="6.5" />
            <circle className={cl.svgTest} cx="75.951022" cy="68.206787" r="6.5" />
            <circle className={cl.svgTest} cx="95.999702" cy="68.206787" r="6.5" />
            <circle className={cl.svgTest} cx="115.97059" cy="68.206787" r="6.5" />
            <circle className={cl.svgTest} cx="75.951022" cy="82.272523" r="6.5" />
            <circle className={cl.svgTest} cx="95.999702" cy="82.272523" r="6.5" />
            <circle className={cl.svgTest} cx="115.97059" cy="82.272523" r="6.5" />
            <circle className={cl.svgTest} cx="95.999702" cy="96.53825" r="6.5" />

            <circle className={cl.svgTest} cx="50.33058" cy="55.51318" r="6.1" />
            <circle className={cl.svgTest} cx="50.33058" cy="68.790094" r="6.1" />
            <circle className={cl.svgTest} cx="50.33058" cy="81.867012" r="6.1" />
            <circle className={cl.svgTest} cx="50.33058" cy="94.90503" r="6.1" />

            <circle className={cl.svgTest} cx="142.05214" cy="68.045685" r="6.5" />
            <circle className={cl.svgTest} cx="142.05214" cy="82.272523" r="6.5" />

            <animate
              attributeName="opacity"
              dur="1s"
              values="0.5;1;0.5"
              repeatCount="indefinite"
            />
          </g>
        </svg> */}
      </div>
    </div>
  );
}
