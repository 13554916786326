import React from 'react';
import cl from './StyleSkudComp.module.css';

export default function BriefDescriptionSkud() {
  return (
    <div className={cl.briefDescriptionSkud}>
      <div className={cl.briefDescriptionSkudOneDiv1}>
        <div className={cl.LeftInfo}>
          <h3>
            Что такое СКУД ?
          </h3>
          <p>
            СКУД - система контроля и управления доступом.
            Это совокупность технических средств, направленных на контроль
            входа и выхода в помещение с целью обеспечения безопасности и
            регулирования посещения определённого объекта.
            С помощью системы идентификации (карта, брелок, отпечатки, универсальный код и т.д.),
            программа индивидуально для каждого рассчитывает его график работы,
            время прихода и ухода, время, потраченное на перерывы и обед.

          </p>
        </div>
      </div>
      <div className={cl.briefDescriptionSkudOneDiv2}>
        <div className={cl.RightInfo}>
          <ul>
            <h3>
              Возможности
            </h3>
            <h4>систем контроля и управления доступом</h4>
            <li>Наложение ограничений на вход</li>
            <li>Допуск в помещение определённому кругу лиц</li>
            <li>Контроль рабочего дня</li>
            <li>Учет рабочего времени</li>
            <li>Возможность применения биометрических данных</li>
            <li>Пропуск по считаванию номерных знаков автомобилей</li>
            <li>Интеграция с охранными системами</li>

          </ul>
        </div>
      </div>

    </div>
  );
}
