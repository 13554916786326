/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from 'react';
import cl from '../../Security/components/StyleComp.module.css';

export default function InformationSecurity() {
  return (
    <div className={cl.InformationSecurityDiv}>
      <div className={cl.InformationSecurityDiv_h2}>
        <h2>
          ИНФОРМАЦИЯ
        </h2>
      </div>
      <div className={cl.InformationSecurityDiv_divP}>
        <p>
          Тревожная сигнализация может использоваться как отдельная система, так и в качестве дополнения к охранной сигнализации.
          Данная система предназначена для вызова помощи в ситуациях угрозы жизни и здоровья: грабеж, нападение, разбой, неадекватное поведение клиентов.
        </p>
        <p>
          При нажатии кнопки, сигнал «Тревога» поступает на пульт охранной организации, где моментально обрабатывается диспетчером и отдается команда на выезд Группы Быстрого Реагирования (ГБР).
          Как правило, время прибытия ГБР должно быть от 3 до 7 минут.
        </p>
        <p>
          Тревожная кнопка может быть установлена в определенном месте рабочей зоны (стационарная) и может быть в виде небольшого переносного пульта (радиоканальная). В обоих случаях есть свои преимущества.
          Мощности стандартной радиоканальной кнопки хватает для передачи сигнала в пределах достаточно большого торгового центра. Для обхода больших охраняемых территорий рекомендуется использовать тревожные кнопки дальнего действия (до 2000м).
        </p>
      </div>
      {/* <div className={cl.alarmSecurityDetails}>
        <div>g</div>
        <div>d</div>
        <div>g</div>
        <div>h</div>

      </div> */}
    </div>
  );
}
