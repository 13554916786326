/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import React, {
  useEffect, useState, useMemo, useContext,
} from 'react';

export const MainContext = React.createContext();

export default function MainContextProvider({ children }) {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleModalServices, setVisibleModalServices] = useState(false);
  const [visibleModalAsk, setVisibleModalAsk] = useState(false);
  const [service, setService] = useState('Не выбрано');
  const [price, setPrice] = useState('0 руб');
  const [city1, setCity1] = useState('Не выбран');

  const value = useMemo(() => ({
    open,
    setOpen,
    open2,
    setOpen2,
    open3,
    setOpen3,
    open4,
    setOpen4,
    visible,
    setVisible,
    visibleModalServices,
    setVisibleModalServices,
    visibleModalAsk,
    setVisibleModalAsk,
    service,
    setService,
    price,
    setPrice,
    city1,
    setCity1,
  }));

  return (
    <MainContext.Provider value={value}>
      {children}
    </MainContext.Provider>
  );
}
