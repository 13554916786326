import React, { useState } from 'react';
import History from './components/History';
import Messages from './components/Messages';
import MyOrders from './components/MyOrders';
import cl from './UserAccount.module.css';

export default function UserAccount() {
  const [component, setComponent] = useState(<MyOrders />);
  const [activeButton, setActiveButton] = useState('1');
  return (
    <div className={cl.userAccountPanel}>
      <div className={cl.box}>
        <div className={cl.leftMenu}>
          <button id="1" className={activeButton === '1' ? `${cl.activeButton}` : `${cl.unActiveButton}`} type="button" onClick={(event) => { setActiveButton(event.target.id); setComponent(<MyOrders />); }}>МОИ ЗАЯВКИ</button>
          <button id="2" className={activeButton === '2' ? `${cl.activeButton}` : `${cl.unActiveButton}`} type="button" onClick={(event) => { setActiveButton(event.target.id); setComponent(<History />); }}> ИСТОРИЯ</button>
          <button id="3" className={activeButton === '3' ? `${cl.activeButton}` : `${cl.unActiveButton}`} type="button" onClick={(event) => { setActiveButton(event.target.id); setComponent(<Messages />); }}>ЧАТ</button>
        </div>
        <div className={cl.rightComponents}>{ component }</div>
      </div>
    </div>
  );
}
