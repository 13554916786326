/* eslint-disable no-unused-vars */
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import cl from './Benefits.module.css';
import fireSec from './img/fireSecuritySbkomi.png';
import skud from './img/skudSbkomi.png';
import video from './img/videoSbkomi.png';
import serv from './img/maintainSbkomi.png';

export default function Benefits() {
  const navigate = useNavigate();
  return (
    <div className={cl.benefitsDiv}>
      <div className={cl.benefitDiv}>
        <h4>Работаем с 2003 года</h4>

        {/* <h5 className={cl.servh3}>Монтаж пожарной сигнализации</h5> */}
      </div>
      <div className={cl.benefitDiv}>

        <h4>Сотрудничаем с крупными поставщиками</h4>

        {/* <h5 className={cl.servh3}>Монтаж пожарной сигнализации</h5> */}
      </div>
      <div className={cl.benefitDiv}>

        <h4>Работаем по всему региону</h4>

        {/* <h5 className={cl.servh3}>Монтаж пожарной сигнализации</h5> */}
      </div>
      <div className={cl.benefitDiv}>

        <h4>Гарантия на все монтажные работы</h4>

        {/* <h5 className={cl.servh3}>Гарантия на все монтажные работы</h5> */}
      </div>
    </div>
  );
}
