import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import cl from './Footer.module.css';
import Logo from './logo.png';

export default function Footer() {
  return (
    <div className={cl.footer}>
      <div className={cl.info_content}>
        <div className={cl.picture}>
          <div className={cl.picture_block}>
            <img src={Logo} alt="logo" />
          </div>
        </div>

        <div className={cl.services}>
          <div className={cl.services_tag_h}>
            <h3>УСЛУГИ</h3>
          </div>
          <div className={cl.services_link}>
            <Link to="/security/#securitypage">Охранная сигнализация</Link>
            <Link to="/firesecurity/#firesecuritypage">Пожарная сигнализация</Link>
            <Link to="/alarm/#alarmpage">Тревожная кнопка</Link>
            <Link to="/video/#videopage">Видеонаблюдение</Link>
            <Link to="/skud/#skudpage">СКУД</Link>
          </div>
        </div>
        <div className={cl.information}>
          <div className={cl.information_tag_h}>
            <h3>ИНФОРМАЦИЯ</h3>
          </div>
          <div className={cl.information_link}>

            <Link to="/#company">О компании</Link>
            <Link to="/#specialisation">Деятельность</Link>
            <Link to="/#licenses">Лицензии</Link>
            <Link to="/#coverage">Города</Link>

          </div>
        </div>
      </div>
      <div className={cl.company_name}>
        <div className={cl.name_comp}>
          {`ООО СТАНДАРТ БЕЗОПАСНОСТИ ${new Date().getFullYear()}`}
        </div>
      </div>
    </div>
  );
}
