import { useEffect, useState } from 'react';
import { BallTriangle } from 'react-loader-spinner';
// eslint-disable-next-line import/no-extraneous-dependencies
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import { Helmet } from 'react-helmet';
import cl from './Contacts.module.css';

function Contats() {
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    setInterval(() => {
      setLoader(false);
    }, 1500);
  });

  return (
    <div className={cl.container}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>СТАНДАРТ БЕЗОПАСНОСТИ Контакты</title>
        <meta name="keywords" content="Стандарт безопасности, стандарт безопасности сыктывкар, стандарт безопасности ухта, стандарт безопасности где находится, стандарт безопасности как проехать, стандарт безопасности время работы, ..." />
        <meta
          name="description"
          content="ООО СТАНДАРТ БЕЗОПАСНОСТИ, г. Сыктывкар, ул. Пушкина, д.30/1, 8(8212)302409, с 9:00 до 18:00 пн-пт"
        />
      </Helmet>
      <div className={cl.contacts_modules}>
        <div className={cl.contacts_map}>
          {loader
            ? (
              <div className={cl.preLoader}>
                <BallTriangle
                  height={100}
                  width={100}
                  radius={5}
                  color="#ff8562"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible
                />
              </div>
            )
            : (
              <div>
                <YMaps>
                  <div>
                    <Map
                      width="100%"
                      height="300px"
                      defaultState={{ center: [61.664924, 50.839246], zoom: 17 }}
                    >
                      <Placemark geometry={[61.664924, 50.839246]} />
                    </Map>
                  </div>
                </YMaps>
              </div>
            )}
        </div>
        <div className={cl.contacts_text}>
          <div className={cl.contacts_container}>
            <div className={cl.contacts_text_phone}>
              <a href="tel:+78212302409">+7 (8212) 302-409</a>
            </div>
            <div className={cl.contacts_text_email}>
              <a href="mailto:standartbezopasnosti1@gmail.com">standartbezopasnosti1@gmail.com</a>
            </div>
            <div className={cl.contacts_text_adress}>
              <h2>Сыктывкар, ул. Пушкина 30/1</h2>
            </div>
            <div className={cl.contacts_text_working}>
              <h2>Режим работы: 9:00-18:00 пн-пт</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contats;
