import { useContext } from 'react';
import cl from './StyleAlarmSecurityComp.module.css';
import './toggleServAndPrFS.css';
// import base from '../img/1990.jpeg';
// import better from '../img/2990.png';
// import norm from '../img/4900.png';
// import fireresolve from '../img/9900.png';
// import calendarPlanFS from '../svg/calendarPlanFs.svg';
import first from '../img/first.png';
import second from '../img/second.png';
import third from '../img/third.png';
import fourth from '../img/fourth.png';
import { MainContext } from '../../../../context/Main.context';

export default function ServicesAndPricesFS() {
  const {
    // visibleModalServices,
    setVisibleModalServices,
    setService,
    setPrice,
  } = useContext(MainContext);
  return (
    <div className={cl.ServicesAndPricesbox}>
      <div className={cl.divH2}>
        <h2> ГОТОВЫЕ РЕШЕНИЯ</h2>
      </div>
      <div className={cl.divServices}>
        <div className={cl.firstPack}>
          <div className={cl.firstPackH3}>
            <h3>ДОПОЛНЕНИЕ К ОХРАННОЙ СИГНАЛИЗАЦИИ</h3>
          </div>
          <div className={cl.firstPackH4}>
            <h4>КОМПЛЕКТ ТРЕВОЖНОЙ СИГНАЛИЗАЦИИ</h4>
          </div>
          <div className={cl.firstPackImg}>
            <img src={first} className={cl.serviceImg} alt="Минимальный набор охранной сигнализации" />
          </div>
          <div className={cl.ulLi_block}>
            <ul className={cl.ul_li_op}>
              <li>
                - Радиобрелок охранной сигнализации
              </li>
              <li>
                - Приемник
              </li>
              <li>
                - Дополнительные брелки
              </li>
            </ul>
          </div>
          <div className={cl.h5}>
            <h5> от 4990 руб</h5>
          </div>
          <div className={cl.btn_block}>
            <button type="button" className={cl.requestButton} onClick={() => { setVisibleModalServices(true); setService('Установка модуля тревожной сигнализации для охранной системы'); setPrice('от 4990 руб'); }}>Оставить заявку</button>
          </div>
        </div>
        <div className={cl.firstPack}>
          <div className={cl.firstPackH3}>
            <h3>СТАЦИОНАРНАЯ ТРЕВОЖНАЯ КНОПКА</h3>
          </div>
          <div className={cl.firstPackH4}>
            <h4>КОМПЛЕКТ ТРЕВОЖНОЙ СИГНАЛИЗАЦИИ С ОХРАННЫМ ПРИБОРОМ</h4>
          </div>
          <div className={cl.firstPackImg}>
            <img src={second} className={cl.serviceImg} alt="Минимальный набор охранной сигнализации" />
          </div>
          <div className={cl.ulLi_block}>
            <ul className={cl.ul_li_op}>
              <li>
                - Радиобрелок охранной сигнализации
              </li>
              <li>
                - Приемник
              </li>
              <li>
                - Дополнительные брелки
              </li>
            </ul>
          </div>
          <div className={cl.h5}>
            <h5> от 6990 руб</h5>
          </div>
          <div className={cl.btn_block}>
            <button type="button" className={cl.requestButton} onClick={() => { setVisibleModalServices(true); setService('Монтаж стационарной тревожной сигнализации'); setPrice('от 6990 руб'); }}>Оставить заявку</button>
          </div>
        </div>
        <div className={cl.firstPack}>
          <div className={cl.firstPackH3}>
            <h3>РАДИОКАНАЛЬНАЯ ТРЕВОЖНАЯ КНОПКА</h3>
          </div>
          <div className={cl.firstPackH4}>
            <h4>КОМПЛЕКТ ТРЕВОЖНОЙ СИГНАЛИЗАЦИИ С ОХРАННЫМ ПРИБОРОМ</h4>
          </div>
          <div className={cl.firstPackImg}>
            <img src={third} className={cl.serviceImg} alt="Минимальный набор охранной сигнализации" />
          </div>
          <div className={cl.ulLi_block}>
            <ul className={cl.ul_li_op}>
              <li>
                - Радиобрелок охранной сигнализации
              </li>
              <li>
                - Приемник
              </li>
              <li>
                - Дополнительные брелки
              </li>
            </ul>
          </div>
          <div className={cl.h5}>
            <h5> от 9990 руб</h5>
          </div>
          <div className={cl.btn_block}>
            <button type="button" className={cl.requestButton} onClick={() => { setVisibleModalServices(true); setService('Монтаж радиоканальной тревожной сигнализации'); setPrice('от 9900 руб'); }}>Оставить заявку</button>
          </div>
        </div>
        <div className={cl.firstPack}>
          <div className={cl.firstPackH3}>
            <h3>ТРЕВОЖНАЯ КНОПКА ДАЛЬНЕГО ДЕЙСТВИЯ</h3>
          </div>
          <div className={cl.firstPackH4}>
            <h4>КОМПЛЕКТ ТРЕВОЖНОЙ СИГНАЛИЗАЦИИ С ОХРАННЫМ ПРИБОРОМ</h4>
          </div>
          <div className={cl.firstPackImg}>
            <img src={fourth} className={cl.serviceImg} alt="Минимальный набор охранной сигнализации" />
          </div>
          <div className={cl.ulLi_block}>
            <ul className={cl.ul_li_op}>
              <li>
                - Радиобрелок охранной сигнализации
              </li>
              <li>
                - Приемник
              </li>
              <li>
                - Дополнительные брелки
              </li>
            </ul>
          </div>
          <div className={cl.h5}>
            <h5> от 10990 руб</h5>
          </div>
          <div className={cl.btn_block}>
            <button type="button" className={cl.requestButton} onClick={() => { setVisibleModalServices(true); setService('Монтаж радиоканальной тревожной сигнализации дальнего действия'); setPrice('от 10990 руб'); }}>Оставить заявку</button>
          </div>
        </div>
      </div>
    </div>
  );
}
