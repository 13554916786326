import React from 'react';
import { Helmet } from 'react-helmet';

import Benefits from './Benefits/Benefits';
import cl from './Main.module.css';
import UnderHeader from './UnderHeader/UnderHeader';
import Map from './Map/Map';
import Licenses from './Licenses/Licenses';
import About from './About/About';
import Specialisation from './Specialisation/Specialisation';

// import ThreeColumns from './ThreeColumns/ThreeColumns';
// import ScrollableAnchor from 'react-scrollable-anchor'

export default function Main() {
  return (
    <div className={cl.MainDiv}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>СТАНДАРТ БЕЗОПАСНОСТИ</title>
        <meta name="keywords" content="Стандарт безопасности, стандарт безопасности сыктывкар, пожарная сигнализация, установка пожарной сигнализации, установка видеонаблюдения, ..." />
        <meta
          name="description"
          content="Установка и обслуживание охранных и противопожарных систем в Республике Коми"
        />
      </Helmet>
      <UnderHeader />
      <Benefits />
      <About />
      <Specialisation />
      {/* <ThreeColumns /> */}
      <Licenses />
      <Map />
    </div>
  );
}
