/* eslint-disable max-len */
import React from 'react';
import wire from '../svg/wire.svg';
import cloud from '../svg/cloud.svg';
import cl from './StyleVideoComponents.module.css';

export default function AnalogOrIp() {
  return (
    <div className={cl.AnalogOrIp}>
      <div className={cl.aboutAnalogOrIp}>
        <h2> АНАЛОГОВОЕ И ЦИФРОВОЕ ВИДЕОНАБЛЮДЕНИЕ</h2>
        <p>
          Главные отличие этих двух видов устройств заключается в способе обработки
          получаемых данных.
          К числу характерных различий следует отнести и неодинаковую степень
          чувствительности к погодным условиям и другим факторам внешней среды.
          Еще одна несхожесть состоит в том, что к цифровой камере можно подключаться
          стандартным персональным компьютером по сети и при участии обычного веб-браузера
          просматривать транслируемую ей картинку.

        </p>
      </div>
      <h4>АНАЛОГОВОЕ ВИДЕОНАБЛЮДЕНИЕ</h4>
      <div className={cl.analog}>
        <img src={wire} className={cl.wireSvg} alt="аналоговое видеонаблюдение" />
        <div className={cl.analogInner}>
          <p>
            Для передачи сигнала в аналоговых камерах применяется
            стандартный коаксиальный кабель с сопротивлением в 75 Ом.
            В эксплуатации аналоговые камеры отличаются простотой,
            что делает их техническое обслуживание недорогим и надежным.
            Во многом именно поэтому подобное оборудование активно используется в офисах,
            супермаркетах, на рынках и т. д.
          </p>
          <div className={cl.analogSpec}>
            <div className={cl.oneSpecType}>
              <h5>Достоинства</h5>
              <ul>
                <li>Доступная цена</li>
                <li>Простое обслуживание</li>
                <li>возможность подключения к наличествующим линиям трансляционной инфраструктуры</li>
                <li>полная независимость от работы сети интернет</li>
                <li>отличная совместимость при использовании моделей от разных производителей</li>
              </ul>
            </div>
            <div className={cl.oneSpecType}>
              <h5>Недостатки</h5>
              <ul>
                <li>Ограниченная зона обзора</li>
                <li>отсутствие возможности автоматически фиксировать фокус на определенном объекте</li>
                <li>непременное наличие видеорегистратора, без которого видео не может записываться на диск или транслироваться по сети</li>
                <li>ограничение видеорегистраторов по числу одновременно обрабатываемых камер</li>
                <li>необходимость прокладки большого количества кабелей для передачи сигнала</li>
                <li>чувствительность к радиочастотным шумам и появляющиеся из-за этого помехи</li>
              </ul>
            </div>
            <div className={cl.comp}>
              <h5>Компоненты</h5>
              <ul>
                <li>Камеры</li>
                <li>видеорегистратор (один или несколько)</li>
                <li>блок питания для камер</li>
                <li>совмещенный коаксиальный кабель</li>
                <li>разъемы питания</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <h4>ЦИФРОВОЕ ВИДЕОНАБЛЮДЕНИЕ</h4>
      <div className={cl.ip}>
        <img src={cloud} className={cl.ipSvg} alt="ip видеонаблюдение" />
        <div className={cl.ipInner}>

          <p>
            В цифровых системах используются самые инновационные устройства
            – IP-камеры с записью для обработки видео. Они «занимаются» не только видеосъемкой,
            но еще и оцифровкой сигнала, сжатием данных,
            а также передачей их по проводам или через интернет.
          </p>
          <div className={cl.ipSpec}>
            <div className={cl.oneSpecType}>
              <h5>Достоинства</h5>
              <ul>
                <li>Возможность фрагментарной записи, позволяющая экономить место на дисках</li>
                <li>наличие моделей с разрешением более 12 Мп, что позволяет получать видеосъемку с высокой детализацией</li>
                <li>наличие функции высокоскоростной и панорамной съемки</li>
                <li>возможность подключать к видеорегистратору практически любое количество камер</li>
              </ul>
            </div>
            <div className={cl.oneSpecType}>
              <h5>Недостатки</h5>
              <ul>
                <li>Высокая стоимость</li>
                <li>невозможность работы без наличия интернета</li>
                <li>наличие разного программного обеспечения для каждой модели</li>
                <li>срабатывание тревожного сигнала не доведено до автоматизма</li>
                <li>сложность настройки</li>
              </ul>
            </div>
            <div className={cl.comp}>
              <h5>Компоненты</h5>
              <ul>
                <li>Камеры</li>
                <li>Видеорегистратор</li>
                <li>сетевой коммутатор – обычный или с PoE</li>
                <li>UTP-кабель (витая пара)</li>
                <li>разъемы питания</li>
                <li>компьютер</li>
              </ul>
            </div>
          </div>
        </div>

      </div>
      <div className={cl.analogOrIpSummary}>
        <h4>ИТОГ</h4>
        <p>
          Понятная обычному покупателю разница между камерами состоит
          в цене и возможностях оборудования. Аналоговые устройства
          станут идеальным вариантом для создания небольшой частной
          системы видеонаблюдения в доме или магазине. IP-камеры пригодятся
          в торговых центрах и на других объектах с интенсивным движением людей.
        </p>
      </div>
    </div>
  );
}
