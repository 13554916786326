/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import { useContext, useState, useEffect } from 'react';
import './App.css';
import { Route, Routes, useLocation } from 'react-router-dom';
import FeedBack from './components/Feedback/Feedback';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import UnderHeader from './components/Main/Main/UnderHeader/UnderHeader';
import cl from './App.module.css';
import Main from './components/Main/Main/Main';
// import Map from './components/Main/Main/Map/Map';
import logo from './logo.svg';
import UserAccount from './components/UserAccount/UserAccount';
import { UserAccountContext } from './context/UserAccount.context';
import Security from './components/Services/Security/Security';
import Contacts from './components/Contacts/Contacts';
import { MainContext } from './context/Main.context';
import ModalForLogin from './components/Main/Main/Modal/ModalForLogin';
import FireSecurity from './components/Services/FireSecurity/FireSecurity';
import AlarmSecurity from './components/Services/AlarmSecurity/AlarmSecurity';
import Video from './components/Services/Video/Video';
import Skud from './components/Services/Skud/Skud';
import ModalForServices from './components/Main/Main/Modal/ModalForServices';
import ModalAskForPrice from './components/Main/Main/Modal/ModalAskForPrice';

function App() {
  const [loading, setLoading] = useState(true);
  const {
    visible, setVisible, visibleModalServices, setVisibleModalServices, setVisibleModalAsk, visibleModalAsk,
  } = useContext(MainContext);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
  // loading ? (
  //   <div className="spinner-container">
  //     <img className="spinner" src="https://i.pinimg.com/originals/e2/eb/9e/e2eb9e845ff87fb8fac15f72359efb10.gif" alt="spinner" />
  //   </div>
  // )
  //   : (
    <div className={cl.myApp}>
      <Header />
      <ModalAskForPrice visibleModalAsk={visibleModalAsk} setVisibleModalAsk={setVisibleModalAsk} />
      <ModalForLogin visible={visible} setVisible={setVisible} />
      <ModalForServices visibleModalServices={visibleModalServices} setVisibleModalServices={setVisibleModalServices} />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/account" element={<UserAccount />} />
        <Route path="/security" element={<Security />} />
        <Route path="/alarm" element={<AlarmSecurity />} />
        <Route path="/firesecurity" element={<FireSecurity />} />
        <Route path="/skud" element={<Skud />} />
        <Route path="/video" element={<Video />} />
        <Route path="/contacts" element={<Contacts />} />
      </Routes>
      <FeedBack />
      <Footer />
    </div>
  );
  // );
}

export default App;

// {user
//   ? (
//     <CandidateContextProvider>
//       <MainPage />
//     </CandidateContextProvider>
//   ) : <AuthForm />}
