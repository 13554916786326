import React, { useContext, useState } from 'react';
import cl from './StyleVideoComponents.module.css';
import analog from '../img/analogVideo.png';
import ip from '../img/ipVideo.png';
import monithoring from '../img/monitoringCenterEstablishment.png';
import { MainContext } from '../../../../context/Main.context';

export default function ServicesAndPricesVideo() {
  const [showServiceVideo, setShowServiceVideo] = useState(false);
  const {
    // visibleModalServices,
    setVisibleModalServices,
    setService,
    setPrice,
  } = useContext(MainContext);

  const handleToggleChangeService = () => {
    setShowServiceVideo(!showServiceVideo);
  };
  return (
    <div className={cl.ServicesAndPricesbox}>
      <div className={cl.divH2}>
        <h2> ГОТОВЫЕ РЕШЕНИЯ</h2>

        <div className="btn-container3">
          <i className="fa3 fa-sun-o3" aria-hidden="true" />
          <label className="switch2 btn-color-mode-switch3">
            <input type="checkbox" name="color_mode3" id="color_mode3" value="1" onClick={handleToggleChangeService} checked={showServiceVideo} />
            <label htmlFor="color_mode3" data-on="Сервис" data-off="Монтаж" className="btn-color-mode-switch-inner3" />
          </label>
          <i className="fa2 fa-moon-o3" aria-hidden="true" />
        </div>
      </div>
      <div className={cl.divServices}>
        {showServiceVideo ? (
          <>
            <div className={cl.firstPack}>
              <div className={cl.h3Andh4}>
                <h3>Ремонт</h3>
              </div>
              <div className={cl.imgServ}>
                <img src={ip} className={cl.svgImg} alt="Разовый вызов техника" />
              </div>
              <div className={cl.pServ}>
                {/* <p>
                4-х шлейфовой прибор. Канал связи GSM.
                Магнитоконтактный датчик на вход и инфракрасных извещатель для входной зоны

              </p> */}
              </div>
              <div className={cl.priceAndBtn}>
                <h4>от 500 руб</h4>
                <button type="button" className={cl.requestButton} onClick={() => { setVisibleModalServices(true); setService('Вызов техника на ремонт видеонаблюдения'); setPrice('от 500 руб'); }}>Оставить заявку</button>
              </div>
            </div>
            <div className={cl.firstPack}>
              <div className={cl.h3Andh4}>
                <h3>Дооснащение</h3>
              </div>
              <div className={cl.imgServ}>
                <img src={ip} className={cl.svgImg} alt="Дооснащение систем" />
              </div>
              <div className={cl.pServ}>
                <p className={cl.pInService}>
                  Установка дополнительного оборудования
                </p>
              </div>
              <div className={cl.priceAndBtn}>
                <h4>По запросу</h4>
                <button type="button" className={cl.requestButton} onClick={() => { setVisibleModalServices(true); setService('Дооснащение системы видеонаблюдения'); setPrice('По запросу'); }}>Оставить заявку</button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={cl.firstPack}>
              <div className={cl.h3Andh4}>
                <h3>Аналоговое видеонаблюдение</h3>
                {/* <h4>Передача сигнала с помощью кабелей</h4> */}
              </div>
              <div className={cl.imgServ}>
                <img src={analog} className={cl.serviceImg} alt="Аналоговое видеонаблюдение" />
              </div>
              <div className={cl.pServ}>
                {/* <p>
                <ul>
                  <li> - 2 дымовых извещателя</li>
                  <li> - Контрольная панель cо встроенным GSM-модулем</li>
                  <li> - Бесплатный гарантийный ремонт 1 год</li>
                </ul>
              </p> */}
              </div>
              <div className={cl.priceAndBtn}>
                <h4>от 15900 руб</h4>
                <button type="button" className={cl.requestButton} onClick={() => { setVisibleModalServices(true); setService('Монтаж системы аналогового видеонаблюдения'); setPrice('от 15900 руб'); }}>Оставить заявку</button>
              </div>
            </div>
            <div className={cl.firstPack}>
              <div className={cl.h3Andh4}>
                <h3>IP видеонаблюдение</h3>
                {/* <h4>Передача сигнала через облачный сервер</h4> */}
              </div>
              <div className={cl.imgServ}>
                <img src={ip} className={cl.serviceImg} alt="Цифровое видеонаблюдение" />
              </div>
              <div className={cl.pServ}>
                {/* <p>
                <ul>
                  <li>- Приемно-контрольный прибор</li>
                  <li>- Дымовые извещатели</li>
                  <li>- Ручные извещатели</li>
                  <li>- Звуковое и световое оповещение</li>
                </ul>
              </p> */}
              </div>
              <h4>от 19500 руб</h4>
              <button type="button" className={cl.requestButton} onClick={() => { setVisibleModalServices(true); setService('Монтаж IP видеонаблюдения'); setPrice('от 19500 руб'); }}>Оставить заявку</button>
            </div>
            <div className={cl.firstPack}>
              <div className={cl.h3Andh4}>
                <h3>Организация центра мониторинга</h3>
                {/* <h4>Для коммерческих объектов</h4> */}
              </div>
              <div className={cl.imgServ}>
                <img src={monithoring} className={cl.serviceImg} alt="Центр мониторинга" />
              </div>
              <div className={cl.pServ}>
                {/* <p>
                <ul>
                  <li>- Адресные извещатели</li>
                  <li>- Источник питания с взрывозащищенным корпусом</li>
                  <li>- Эвакуационные оповещатели</li>
                  <li>- Пульт контроля и управления</li>
                  <li>- Устройство оконечное объектовое</li>
                  <li>- Блок индикации с клавиатурой</li>
                </ul>
              </p> */}
              </div>
              <div className={cl.priceAndBtn}>
                <h4>По запросу</h4>
                <button type="button" className={cl.requestButton} onClick={() => { setVisibleModalServices(true); setService('Организация центра мониторинга на объекте'); setPrice('По запросу'); }}>Оставить заявку</button>
              </div>
            </div>
          </>
        )}

      </div>
    </div>
  );
}
