import React from 'react';
import { Helmet } from 'react-helmet';
import AboutFireSecuritySystems from './components/AboutFireSecuritySystems';
import InformationFireSecurity from './components/InformationFireSecurity';
import ServicesAndPricesFS from './components/ServicesAndPricesFS';
import UnderHeaderFireSecurity from './components/UnderHeaderFireSecurity';
import cl from './FireSecurity.module.css';

export default function FireSecurity() {
  return (
    <div id="firesecuritypage" className={cl.fireSecurityComp}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>СТАНДАРТ БЕЗОПАСНОСТИ Пожарная сигнализация</title>
        <meta name="keywords" content="Стандарт безопасности, стандарт безопасности сыктывкар, установка пожарной сигнализации, дымовые датчики, СОУЭ, пожарная сигнализация, ..." />
        <meta
          name="description"
          content="Установка и обслуживание пожарной сигнализации и систем пожаротушения от 10500 руб"
        />
      </Helmet>
      <UnderHeaderFireSecurity />
      <AboutFireSecuritySystems />
      <InformationFireSecurity />
      <ServicesAndPricesFS />
    </div>
  );
}
