/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import cl from './Specialisation.module.css';
import securitySpecPhoto from './img/securitySpec.png';
import fireSpecPhoto from './img/fireSpec.png';
import fireFightSpecPhoto from './img/fireFightSpec.png';
import alarmSpecPhoto from './img/alarmSpec.png';
import videoSpecPhoto from './img/videoSpec.png';
import skudSpecPhoto from './img/skudSpec.png';
import ThreeColumns from '../ThreeColumns/ThreeColumns';

export default function Specialisation() {
  const [spec, setSpec] = useState('fireSpec');
  const [specPhoto, setSpecPhoto] = useState(fireSpecPhoto);
  const [specHref, setSpecHref] = useState('/firesecurity');
  const [specName, setSpecName] = useState('ПОЖАРНАЯ СИГНАЛИЗАЦИЯ');

  const setSpecFunc = (e) => {
    setSpec(e.target.id);
    console.log(e.target.id);
  };

  const setRightPartFunc = (e) => {
    switch (e.target.id) {
      case 'securitySpec':
        return (setSpecPhoto(securitySpecPhoto), setSpecHref('/security'), setSpecName('ОХРАННАЯ СИГНАЛИЗАЦИЯ'));
      case 'fireSpec':
        return (setSpecPhoto(fireSpecPhoto), setSpecHref('/firesecurity'), setSpecName('ПОЖАРНАЯ СИГНАЛИЗАЦИЯ'));
      case 'fireFightSpec':
        return (setSpecPhoto(fireFightSpecPhoto), setSpecHref('/firesecurity'), setSpecName('СИСТЕМЫ ПОЖАРОТУШЕНИЯ'));
      case 'alarmSpec':
        return (setSpecPhoto(alarmSpecPhoto), setSpecHref('/alarm'), setSpecName('ТРЕВОЖНАЯ СИГНАЛИЗАЦИЯ'));
      case 'videoSpec':
        return (setSpecPhoto(videoSpecPhoto), setSpecHref('/video'), setSpecName('ВИДЕОНАБЛЮДЕНИЕ'));
      case 'skudSpec':
        return (setSpecPhoto(skudSpecPhoto), setSpecHref('/skud'), setSpecName('СКУД'));
      default: return 'об.';
    }
  };

  return (
    <>
      <h2 id="specialisation" className={cl.specH2}> ДЕЯТЕЛЬНОСТЬ </h2>
      <div className={cl.allAboutSpecialisation}>
        <div className={cl.aboutleft}>
          <h3 id="fireSpec" className={spec === 'fireSpec' ? `${cl.chosen}` : `${cl.notChosen}`} onClick={(e) => { setSpecFunc(e); setRightPartFunc(e); }}>ПОЖАРНАЯ СИГНАЛИЗАЦИЯ</h3>
          <h3 id="fireFightSpec" className={spec === 'fireFightSpec' ? `${cl.chosen}` : `${cl.notChosen}`} onClick={(e) => { setSpecFunc(e); setRightPartFunc(e); }}>СИСТЕМЫ ПОЖАРОТУШЕНИЯ</h3>
          <h3 id="securitySpec" className={spec === 'securitySpec' ? `${cl.chosen}` : `${cl.notChosen}`} onClick={(e) => { setSpecFunc(e); setRightPartFunc(e); }}>ОХРАННАЯ СИГНАЛИЗАЦИЯ</h3>
          <h3 id="alarmSpec" className={spec === 'alarmSpec' ? `${cl.chosen}` : `${cl.notChosen}`} onClick={(e) => { setSpecFunc(e); setRightPartFunc(e); }}>ТРЕВОЖНАЯ КНОПКА</h3>
          <h3 id="videoSpec" className={spec === 'videoSpec' ? `${cl.chosen}` : `${cl.notChosen}`} onClick={(e) => { setSpecFunc(e); setRightPartFunc(e); }}>ВИДЕОНАБЛЮДЕНИЕ</h3>
          <h3 id="skudSpec" className={spec === 'skudSpec' ? `${cl.chosen}` : `${cl.notChosen}`} onClick={(e) => { setSpecFunc(e); setRightPartFunc(e); }}>СИСТЕМЫ УПРАВЛЕНИЯ ДОСТУПОМ</h3>
        </div>
        <div className={cl.aboutRight}>
          <div className={cl.rightAll}>
            <h3>{specName}</h3>
            <div className={cl.imgBox}>
              <img src={specPhoto} alt="изображение" className={cl.serviceImg} />
            </div>
            <div>
              <Link to={specHref}><button type="button" className={cl.moreAboutSpec}>Подробнее</button></Link>
            </div>

          </div>

        </div>
      </div>
      <ThreeColumns />
    </>
  );
}
