/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from 'react';
import cl from './StyleComp.module.css';

export default function InformationSecurity() {
  return (
    <div className={cl.InformationSecurityDiv}>
      <h2>
        ИНФОРМАЦИЯ
      </h2>
      <div className={cl.InformationSecurityDiv_divP}>
        <p>
          Охранная сигнализация - это сложный комплекс технических средств установленных на объекте.
          Основой любой охранной сигнализации является охранный прибор передающий
          сигналы на пульт централизованного
          наблюдения (ПЦН) и комплекс извещателей и датчиков, подключенных к прибору.
        </p>
        <p>
          В настоящее время, эта услуга особенно востребована. Охранная сигнализация необходима,
          если на объекте находятся ценные вещи, важные документы, а также если объект находится без
          постоянного присутствия в нем людей.
        </p>
        <p>
          Установив охранную сигнализацию, вы обеспечите сохранность вашего имущества, так как в
          большинстве случаев грабители и вандалы избегают объекты
          оборудованные системами безопасности,
          а в случае тревоги, охранное предприятие отвечающее за охрану вашего объекта
          сможет моментально обнаружить в какой части вашего объекта произошел взлом или проникновение
          и сможет оперативно принять все необходимые меры для пресечения противоправных
          действий преступников.
        </p>
        <p>
          Современное оборудование позволяется с точностью определить причину тревоги и
          место, где произошла сработка.
          Возможности настроек и разнообразные комбинации извещателей - позволяют
          кастомизировать системы безопасности практические под любые условия эксплуатации.
        </p>
      </div>
    </div>
  );
}
