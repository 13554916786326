/* eslint-disable max-len */
import React, { useContext, useState } from 'react';
import cl from './StyleSkudComp.module.css';

import autonom from '../svg/skud_auto.svg';
import net from '../svg/skud_net.svg';
import netTime from '../svg/skud_net_time.svg';
import turnStile from '../svg/turnstile-svgr.svg';
import { MainContext } from '../../../../context/Main.context';

export default function ServicesSkud() {
  const [showServiceSkud, setShowServiceSkud] = useState(false);
  const {
    // visibleModalServices,
    setVisibleModalServices,
    setService,
    setPrice,
  } = useContext(MainContext);

  const handleToggleChangeServiceSkud = () => {
    setShowServiceSkud(!showServiceSkud);
  };

  return (
    <div className={cl.ServicesAndPricesbox}>
      <div className={cl.divH2}>
        <h2> ГОТОВЫЕ РЕШЕНИЯ</h2>

        <div className="btn-container2">
          <i className="fa2 fa-sun-o2" aria-hidden="true" />
          <label className="switch2 btn-color-mode-switch2">
            <input type="checkbox" name="color_mode2" id="color_mode2" value="1" onClick={handleToggleChangeServiceSkud} checked={showServiceSkud} />
            <label htmlFor="color_mode2" data-on="Сервис" data-off="Монтаж" className="btn-color-mode-switch-inner2" />
          </label>
          <i className="fa2 fa-moon-o2" aria-hidden="true" />
        </div>
      </div>
      <div className={cl.divServices}>
        {showServiceSkud ? (
          <>
            <div className={cl.firstPack}>
              <div className={cl.h3Andh4}>
                <h3>Обновление прав доступа</h3>
              </div>
              <div className={cl.imgServ}>
                <img src={autonom} className={cl.svgImg} alt="Обновление прав доступа" />
              </div>
              <div className={cl.pServ}>
                <p>Выдача новых ключей доступа, блокировка старых, настройка прав доступа</p>
              </div>
              <div className={cl.priceAndBtn}>
                <h4> 500 руб</h4>
                <button type="button" className={cl.requestButton} onClick={() => { setVisibleModalServices(true); setService('Вызов техника для настройки прав доступа'); setPrice('500 руб'); }}>Оставить заявку</button>
              </div>
            </div>
            <div className={cl.firstPack}>
              <div className={cl.h3Andh4}>
                <h3>Сервис</h3>
              </div>
              <div className={cl.imgServ}>
                <img src={net} className={cl.svgImg} alt="скуд сервис" />
              </div>
              <div className={cl.pServ}>
                <p>Ремонт, обновление программного обеспечение, расширение системы</p>
              </div>
              <div className={cl.priceAndBtn}>
                <h4>от 1500 руб</h4>
                <button type="button" className={cl.requestButton} onClick={() => { setVisibleModalServices(true); setService('Вызов техника на ремонт СКУД'); setPrice('от 1500 руб'); }}>Оставить заявку</button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={cl.firstPack}>
              <div className={cl.h3Andh4}>
                <h3>Автономная СКУД</h3>
              </div>
              <div className={cl.imgServ}>
                <img src={autonom} className={cl.serviceImg} alt="автономная скуд" />
              </div>
              <div className={cl.pServ}>
                <p>Система представляет собой альтернативу дверным замкам. Такие системы контроля доступа не требуют подключение к компьютеру</p>
              </div>
              <div className={cl.priceAndBtn}>
                <h4>от 12000 руб</h4>
                <button type="button" className={cl.requestButton} onClick={() => { setVisibleModalServices(true); setService('Монтаж автономной СКУД'); setPrice('от 12000 руб'); }}>Оставить заявку</button>
              </div>
            </div>
            <div className={cl.firstPack}>
              <div className={cl.h3Andh4}>
                <h3>Сетевая СКУД</h3>
              </div>
              <div className={cl.imgServ}>
                <img src={net} className={cl.serviceImg} alt="сетевая скуд" />
              </div>
              <div className={cl.pServ}>
                {/* <p>Сетевые устройства контроля и управления доступом позволяют настраивать проход в помещение по расписанию, могут интегрироваться с камерами видеонаблюдения и охранной сигнализацией</p> */}
                <p>Сетевые устройства контроля и управления доступом могут интегрироваться с камерами видеонаблюдения и охранной сигнализацией</p>

              </div>
              <div className={cl.priceAndBtn}>
                <h4>от 29000 руб</h4>
                <button type="button" className={cl.requestButton} onClick={() => { setVisibleModalServices(true); setService('Монтаж сетевой СКУД'); setPrice('от 29000 руб'); }}>Оставить заявку</button>
              </div>
            </div>
            <div className={cl.firstPack}>
              <div className={cl.h3Andh4}>
                <h3>Сетевые СКУД с контролем рабочего времени</h3>
              </div>
              <div className={cl.imgServ}>
                <img src={netTime} className={cl.serviceImg} alt="скуд с контролем рабочего времени" />
              </div>
              <div className={cl.pServ}>
                {/* <p>Система управления доступа с возможностью задавать и контролировать время присутствия и отсутствия сотрудника на рабочем месте.</p> */}
                <p>Система управления доступа с возможностью задавать и контролировать время присутствия и отсутствия сотрудника на рабочем месте.</p>

              </div>
              <div className={cl.priceAndBtn}>
                <h4>от 43000 руб</h4>
                <button type="button" className={cl.requestButton} onClick={() => { setVisibleModalServices(true); setService('Монтаж СКУД с контролем рабочего времени'); setPrice('от 43000 руб'); }}>Оставить заявку</button>
              </div>
            </div>
            <div className={cl.firstPack}>
              <div className={cl.h3Andh4}>
                <h3>Системы ограничения прохода</h3>
              </div>
              <div className={cl.imgServ}>
                <img src={turnStile} className={cl.serviceImg} alt="шлагбаумы и турникеты" />
              </div>
              <div className={cl.pServ}>
                <p>Установка шлагбаумов и турникетов с автоматизированным или ручным управлением</p>
              </div>
              <div className={cl.priceAndBtn}>
                <h4>от 25900 руб</h4>
                <button type="button" className={cl.requestButton} onClick={() => { setVisibleModalServices(true); setService('Монтаж системы ограничения прохода'); setPrice('от 25900 руб'); }}>Оставить заявку</button>
              </div>
            </div>
          </>
        )}

      </div>

    </div>
  );
}
