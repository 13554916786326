import React from 'react';
import cl from '../../Security/components/StyleComp.module.css';

export default function InformationFireSecurity() {
  return (
    <div className={cl.InformationSecurityDiv}>
      <div className={cl.InformationSecurityDiv_h2}>
        <h2>
          ИНФОРМАЦИЯ
        </h2>
      </div>
      <div className={cl.InformationSecurityDiv_divP}>
        <p>
          Пожарная сигнализация - основополагающий элемент любой комплексной системы безопасности.
          Согласно законодательству, пожарная сигнализация - обязательна к установке
          на любом коммерческом или производственном объекте.
        </p>

        <p>
          Пожарная сигнализация представляет собой комплекс технических средств,
          необходимый для моментального обнаружения задымления и возгорания в помещениях,
          а также активации систем помощи при эвакуации и передачи тревоги
          на пульт ЧОП и пожарной службы.
        </p>

        <p>
          Принцип работы пожарной сигнализации заключается в постоянном
          считывании сигналов с датчиков.
          В случае попадания дыма или сильного увеличения температуры в зоне
          работы датчика, происходит передача сигнала тревоги на прибор,
          который включает звуковые и световые системы оповещения о пожаре,
          отправляет тревожный сигнал на пульт и при наличии,
          активирует системы автоматического пожаротушения.
        </p>
      </div>
      {/* <div className={cl.alarmSecurityDetails}>
      <div>g</div>
      <div>d</div>
      <div>g</div>
      <div>h</div>

    </div> */}
    </div>
  );
}
