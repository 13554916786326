import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import MainContextProvider from './context/Main.context';
import UserAccountContextProvider from './context/UserAccount.context';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <MainContextProvider>
    <UserAccountContextProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </UserAccountContextProvider>
  </MainContextProvider>,
);
