/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { showToast } from '../../../../lib/toastify';
import cl from './ModalStyle.module.css';
// import ModalTimer from '../UI/ModalTimer/ModalTimer';
// import QuestionForm from '../QuestionForm/QuestionForm';

const initialvalue = {
  title: '',
  content: '',
  startDate: '',
  endDate: '',
  taskForUserId: '',

};
// children,
function ModalForLogin({ children, visible, setVisible }) {
  // const { visible, setVisible } = useEffect(MainContext);
  const rootClasses = [cl.myModal];
  if (visible) {
    rootClasses.push(cl.active);
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    showToast({ message: 'Пользователь не зарегистрирован!', type: 'error' });
    e.target.reset();
  };
  return (
    <div className={rootClasses.join(' ')} onClick={() => setVisible(false)}>
      <div className={cl.myModalContent} onClick={(e) => e.stopPropagation()}>
        <h4> Войти в кабинет клиента</h4>
        {/* {children} */}
        <form className={cl.loginForm} onSubmit={handleSubmit}>
          <label className={cl.formLabel}>Введите логин</label>
          <input type="text" required />
          <label className={cl.formLabel}>Введите пароль</label>
          <input type="password" required />
          <button type="submit" className={cl.loginFormButton}>Войти</button>
          <ToastContainer />
        </form>

      </div>
    </div>
  );
}

export default ModalForLogin;
