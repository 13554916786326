/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { showToast } from '../../../../lib/toastify';
import { MainContext } from '../../../../context/Main.context';

import cl from './ModalStyle.module.css';

function ModalForServices({ children, visibleModalServices, setVisibleModalServices }) {
  const {
    service,
    setService,
    price,
    setPrice,
  } = useContext(MainContext);

  const BOT = '5902675497:AAGOd3epHnoXAN1of9VWD30XhNVd7C0S-E4';

  const CHAT_ID = '-781052386';

  const defaultValue = {
    name: '', phone: '', email: '',
  };
  const [dataService, setDataService] = useState(defaultValue);

  const br = '%0A';
  const msg = `Новая заявка с сайта www.sbkomi.ru:${br}${br}Тип заявки:${br}Услуга ${service}${br}${br}Стоимость:${price}${br}${br}Имя пользователя:${br}${dataService.name}${br}${br}Телефон пользователя:${br}${dataService.phone}${br}${br}Email пользователя:${br}${dataService.email}${br}${br}`;
  const url = `https://api.telegram.org/bot${BOT}/sendMessage?chat_id=${CHAT_ID}&parse_mode=HTML&text=${msg}`;

  const rootClasses = [cl.myModal];
  if (visibleModalServices) {
    rootClasses.push(cl.active);
  }
  const handleInput = (e) => {
    setDataService({ ...dataService, [e.target.name]: e.target.value });
  };
  const handleSubmitServ = (e) => {
    e.preventDefault();
    fetch(url, {
      method: 'POST',
    });
    e.target.reset();
    setTimeout(() => { setVisibleModalServices(false); }, 1500);
    showToast({ message: 'Заявка отправлена!', type: 'success' });
    //   .finally(console.log('fdfd'), setDataService(defaultValue));
  };
  return (
    <div className={rootClasses.join(' ')} onClick={() => { setVisibleModalServices(false); setPrice('н.д'); setService('Не выбрано'); }}>
      <div className={cl.myModalContent} onClick={(e) => e.stopPropagation()}>
        {/* {children} */}
        <form className={cl.loginForm} onSubmit={handleSubmitServ} data-service={service} data-price={price}>
          <h2>Оставить заявку</h2>
          {/* <form onSubmit={handleSubmit}> */}
          <h3>{service}</h3>
          <h4>
            Стоимость:
            {' '}
            {' '}
            {price}
          </h4>
          <label className={cl.formLabel}>Как к вам обращаться?</label>
          <input type="text" name="name" onChange={handleInput} required />
          <label className={cl.formLabel}>Телефон</label>
          <input type="phone" name="phone" onChange={handleInput} required />
          <label className={cl.formLabel}>E-mail</label>
          <input type="email" name="email" onChange={handleInput} required />
          <button type="submit" className={cl.loginFormButton}>Отправить</button>
          <ToastContainer />
        </form>

        {/* <ToastContainer /> */}
        {children}
        {/* </form> */}

      </div>
    </div>
  );
}
export default ModalForServices;

{ /* <label htmlFor="chosenService">Услуги</label>
<select name="chosenService" id="chosenService" onChange={(e) => { setPrice(`${e.target.options[e.target.selectedIndex].dataset.price}`); console.log(e.target.dataset.musor, 'таргет датасет'); }}>
              <optgroup label="Ваш выбор">
                <option value={service} data-price={prevPrice}>
                  {service}
                </option>
              </optgroup>
              <optgroup label="Охранная сигнализация">
                <option value="Базовая ОС от 7900 руб" data-price="от 7900 руб">Базовый комплект</option>
                <option value="Стандартная ОС от 9900 руб" data-price="от 9900 руб">Стандартный комплект</option>
                <option value="Оптимальная ОС от 14900 руб" data-price="от 14900 руб">Оптимальный комплект</option>
                <option value="Максимальная ОС от 18900 руб" data-price="от 18900 руб">Максимальный комплект</option>
                <option value="Выезд техника для ремонта ОС от 500 руб" data-price="от 500 руб">Выезд техника на ремонт ОС</option>
                <option value="Договор на обслуживание ОС от 1500 руб" data-price="от 1500 руб">Регулярное обслуживание ОС</option>
              </optgroup>
              <optgroup label="Пожарная сигнализация">
                <option value="Базовая ОС от 7900 руб" data-price="от 7900 руб">Базовый комплект</option>
                <option value="Стандартная ОС от 9900 руб" data-price="от 9900 руб">Стандартный комплект</option>
                <option value="Оптимальная ОС от 14900 руб" data-price="от 14900 руб">Оптимальный комплект</option>
                <option value="Максимальная ОС от 18900 руб" data-price="от 18900 руб">Максимальный комплект</option>
              </optgroup>
              <optgroup label="Тревожная сигнализация">
                <option value="Базовая ОС от 7900 руб" data-price="от 7900 руб">Базовый комплект</option>
                <option value="Стандартная ОС от 9900 руб" data-price="от 9900 руб">Стандартный комплект</option>
                <option value="Оптимальная ОС от 14900 руб" data-price="от 14900 руб">Оптимальный комплект</option>
                <option value="Максимальная ОС от 18900 руб" data-price="от 18900 руб">Максимальный комплект</option>
              </optgroup>
              <optgroup label="Видеонаблюдение">
                <option value="Базовая ОС от 7900 руб" data-price="от 7900 руб">Базовый комплект</option>
                <option value="Стандартная ОС от 9900 руб" data-price="от 9900 руб">Стандартный комплект</option>
                <option value="Оптимальная ОС от 14900 руб" data-price="от 14900 руб">Оптимальный комплект</option>
                <option value="Максимальная ОС от 18900 руб" data-price="от 18900 руб">Максимальный комплект</option>
              </optgroup>
              <optgroup label="СКУД">
                <option value="Базовая ОС от 7900 руб" data-price="от 7900 руб">Базовый комплект</option>
                <option value="Стандартная ОС от 9900 руб" data-price="от 9900 руб">Стандартный комплект</option>
                <option value="Оптимальная ОС от 14900 руб" data-price="от 14900 руб">Оптимальный комплект</option>
                <option value="Максимальная ОС от 18900 руб" data-price="от 18900 руб">Максимальный комплект</option>
              </optgroup>
            </select> */ }
