/* eslint-disable react/destructuring-assignment */
import React from 'react';
import cl from './FlyoutNavBar.module.css';

export default function DropdownItem(props) {
  console.log(props);
  return (
    <li className={cl.dropdownItem}>
      <img src={props.img} alt="chtoto" />
      <p>{props.text}</p>
    </li>
  );
}
