import React, { useContext, useState } from 'react';
import cl from './StyleFireSecurityComp.module.css';
import './toggleServAndPrFS.css';
import base from '../img/baseFS.png';
import better from '../img/FS_SOYE.png';
import norm from '../img/FS_SOYE_big.png';
import fireresolve from '../img/fireResolve.png';
import calendarPlanFS from '../svg/calendarPlanFs.svg';
import { MainContext } from '../../../../context/Main.context';

export default function ServicesAndPricesFS() {
  const [showServiceFS, setShowServiceFS] = useState(false);
  const {
    // visibleModalServices,
    setVisibleModalServices,
    setService,
    setPrice,
  } = useContext(MainContext);
  const handleToggleChangeService = () => {
    setShowServiceFS(!showServiceFS);
  };
  return (
    <div className={cl.ServicesAndPricesbox}>
      <div className={cl.divH2}>
        <h2> ГОТОВЫЕ РЕШЕНИЯ</h2>

        <div className="btn-container3">
          <i className="fa3 fa-sun-o3" aria-hidden="true" />
          <label className="switch2 btn-color-mode-switch3">
            <input type="checkbox" name="color_mode3" id="color_mode3" value="1" onClick={handleToggleChangeService} checked={showServiceFS} />
            <label htmlFor="color_mode3" data-on="Сервис" data-off="Монтаж" className="btn-color-mode-switch-inner3" />
          </label>
          <i className="fa2 fa-moon-o3" aria-hidden="true" />
        </div>
      </div>
      <div className={cl.divServices}>
        {showServiceFS ? (
          <>
            {/* <div className={cl.firstPack}>
              <h3>РВР</h3>

              <img src={calendarToday} className={cl.svgImg} alt="Разовый вызов техника" />

              <p>
                4-х шлейфовой прибор. Канал связи GSM.
                Магнитоконтактный датчик на вход и инфракрасных извещатель для входной зоны

              </p>
              <h4>от 5000 руб</h4>
              <button type="button" className={cl.requestButton}>Оставить заявку</button>
            </div> */}
            <div className={cl.firstPack}>
              <div className={cl.h3Andh4}>
                <h3>Плановое обслуживание</h3>
              </div>
              <div className={cl.imgServ}>
                <img src={calendarPlanFS} className={cl.svgImg} alt="Плановое обслуживание" />
              </div>
              <div className={cl.pServ2}>
                <p>
                  Ежемесячное или ежеквартальное
                </p>
              </div>
              <div className={cl.priceAndBtn}>
                <h4>от 500 руб</h4>
                <button type="button" className={cl.requestButton} onClick={() => { setVisibleModalServices(true); setService('Обслуживание пожарной сигнализации'); setPrice('от 1500 руб'); }}>Оставить заявку</button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={cl.firstPack}>
              <div className={cl.h3Andh4}>
                <h3>Пожарная сигнализация</h3>
                {/* <h4>Для частной недвижимости</h4> */}
              </div>
              <div className={cl.imgServ}>
                <img src={base} className={cl.serviceImg} alt="Пожарная сигнализация для дома" />
              </div>
              <div className={cl.pServ}>
                <p>
                  - 2 дымовых извещателя
                  - Контрольная панель cо встроенным GSM-модулем
                  - Бесплатный гарантийный ремонт 1 год
                </p>
              </div>
              <div className={cl.priceAndBtn}>
                <h5>от 10500 руб</h5>
                <button type="button" className={cl.requestButton} onClick={() => { setVisibleModalServices(true); setService('Пожарная сигнализация для дома (монтаж + оборудование)'); setPrice('от 10500 руб'); }}>Оставить заявку</button>
              </div>
            </div>
            <div className={cl.firstPack}>
              <div className={cl.h3Andh4}>
                <h3>Пожарная сигнализация + СОУЭ</h3>
                {/* <h4>Для частной и коммерческой недвижимости</h4> */}
              </div>
              <div className={cl.imgServ}>
                <img src={better} className={cl.serviceImg} alt="Пожарная сигнализация + СОУЭ" />
              </div>
              <div className={cl.pServ}>
                <p>
                  - Приемно-контрольный прибор
                  - Дымовые извещатели
                  - Ручные извещатели
                  - Звуковое и световое оповещение
                </p>
              </div>
              <div className={cl.priceAndBtn}>
                <h5>от 14900 руб</h5>
                <button type="button" className={cl.requestButton} onClick={() => { setVisibleModalServices(true); setService('Пожарная сигнализация и СОУЭ (монтаж + обоорудование)'); setPrice('от 14900 руб'); }}>Оставить заявку</button>
              </div>
            </div>
            <div className={cl.firstPack}>
              <div className={cl.h3Andh4}>
                <h3>Пожарная сигнализация + СОУЭ</h3>
                <h4>Для крупных объектов</h4>
              </div>
              <div className={cl.imgServ}>
                <img src={norm} className={cl.serviceImg} alt="Пожарная сигнализация + СОУЭ для крупных объектов" />
              </div>
              <div className={cl.pServ}>
                <p>
                  - Адресные извещатели
                  - Источник питания с взрывозащищенным корпусом
                  - Эвакуационные оповещатели
                  - Пульт контроля и управления
                  - Устройство оконечное объектовое
                  - Блок индикации с клавиатурой
                </p>
              </div>
              <div className={cl.priceAndBtn}>
                <h5>По запросу</h5>
                <button type="button" className={cl.requestButton} onClick={() => { setVisibleModalServices(true); setService('Пожарная сигнализация и СОУЭ (для крупных объектов)'); setPrice('По запросу'); }}>Оставить заявку</button>
              </div>
            </div>
            <div className={cl.firstPack}>
              <div className={cl.h3Andh4}>
                <h3>Система пожаротушения</h3>
                {/* <h4>Для крупных коммерческих объектов</h4> */}
              </div>
              <div className={cl.imgServ}>

                <img src={fireresolve} className={cl.serviceImg} alt="Максимальный набор охранной сигнализации" />

              </div>
              <div className={cl.pServ}>
                {/* <p>
                - Проектирование систем пожаротушения
                - Закупка оборудования
                - Монтаж и подключение систем
                - Пуско-наладочные работы
              </p> */}
              </div>
              <div className={cl.priceAndBtn}>
                <h5>По запросу</h5>
                <button type="button" className={cl.requestButton} onClick={() => { setVisibleModalServices(true); setService('Система пожаротушения (монтаж + оборудование)'); setPrice('По запросу'); }}>Оставить заявку</button>
              </div>
            </div>
          </>
        )}

      </div>

    </div>
  );
}
