/* eslint-disable max-len */
import React, { useContext, useState } from 'react';
import minpack from '../img/minpack.png';
import standpack from '../img/standPack.png';
import optimalpack from '../img/optimalPack.png';
import maxpack from '../img/maxPack.png';
import calendarToday from '../svg/calendarToday.svg';
import calendarPlan from '../svg/calendarPlan.svg';
import cl from './StyleComp.module.css';
import './toggleServAndPr.css';
import { MainContext } from '../../../../context/Main.context';

export default function ServicesAndPrices() {
  const [showService, setShowService] = useState(false);
  const {
    // visibleModalServices,
    setVisibleModalServices,
    setService,
    setPrice,
  } = useContext(MainContext);

  const handleToggleChangeService = () => {
    setShowService(!showService);
  };

  return (
    <div className={cl.ServicesAndPricesbox}>
      <div className={cl.divH2}>
        <h2> ГОТОВЫЕ РЕШЕНИЯ</h2>

        <div className="btn-container2">
          <i className="fa2 fa-sun-o2" aria-hidden="true" />
          <label className="switch2 btn-color-mode-switch2">
            <input type="checkbox" name="color_mode2" id="color_mode2" value="1" onClick={handleToggleChangeService} checked={showService} />
            <label htmlFor="color_mode2" data-on="Сервис" data-off="Монтаж" className="btn-color-mode-switch-inner2" />
          </label>
          <i className="fa2 fa-moon-o2" aria-hidden="true" />
        </div>
      </div>
      <div className={cl.divServices}>
        {showService ? (
          <>
            <div className={cl.firstPack}>
              <div className={cl.h3Andh4}>
                <h3>Выезд техника</h3>
              </div>
              <div className={cl.imgServ}>
                <img src={calendarToday} className={cl.svgImg} alt="Разовый вызов техника" />
              </div>
              {/* <div className={cl.pServ}>
                <p>4-х шлейфовой прибор. Канал связи GSM. Магнитоконтактный датчик на вход и инфракрасных извещатель для входной зоны</p>
              </div> */}
              <div className={cl.priceAndBtn}>
                <h4>от 500 руб</h4>
                <button type="button" className={cl.requestButton} onClick={() => { setVisibleModalServices(true); setService('Вызов техника на ремонт ОС'); setPrice('от 500 руб'); }}>Оставить заявку</button>
              </div>
            </div>
            <div className={cl.firstPack}>
              <div className={cl.h3Andh4}>
                <h3>Ежемесячное обслуживание</h3>
              </div>
              <div className={cl.imgServ}>
                <img src={calendarPlan} className={cl.svgImg} alt="Ежемесячное обслуживание" />
              </div>
              {/* <div className={cl.pServ}>
                <p>4-х шлейфовой прибор. Канал связи GSM. Магнитоконтактный датчик на вход и инфракрасных извещатель для входной зоны</p>
              </div> */}
              <div className={cl.priceAndBtn}>
                <h4>от 500 руб</h4>
                <button type="button" className={cl.requestButton} onClick={() => { setVisibleModalServices(true); setService('Регулярное обслуживание ОС'); setPrice('от 1000 руб'); }}>Оставить заявку</button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={cl.firstPack}>
              <div className={cl.h3Andh4}>
                <h3>Базовый</h3>
              </div>
              <div className={cl.imgServ}>
                <img src={minpack} className={cl.serviceImg} alt="Минимальный набор охранной сигнализации" />
              </div>
              <div className={cl.pServ}>
                <p>4-х шлейфовой прибор. Канал связи GSM. Магнитоконтактный датчик на вход и инфракрасных извещатель для входной зоны</p>
              </div>
              <div className={cl.priceAndBtn}>
                <h4>от 7900 руб</h4>
                <button type="button" className={cl.requestButton} onClick={() => { setVisibleModalServices(true); setService('Охранная сигнализация (Базовый комплект)'); setPrice('от 7900 руб'); }}>Оставить заявку</button>
              </div>
            </div>
            <div className={cl.firstPack}>
              <div className={cl.h3Andh4}>
                <h3>Стандартный</h3>
              </div>
              <div className={cl.imgServ}>
                <img src={standpack} className={cl.serviceImg} alt="Стандартный набор охранной сигнализации" />
              </div>
              <div className={cl.pServ}>
                <p>4-х шлейфовой прибор. Канал связи GSM. Комплект магнитоконтактных и инфракрасных извещателей для основных зон риска</p>
              </div>
              <div className={cl.priceAndBtn}>
                <h4>от 9900 руб</h4>
                <button type="button" className={cl.requestButton} onClick={() => { setVisibleModalServices(true); setService('Охранная сигнализация (Стандартный комплект)'); setPrice('от 9900 руб'); }}>Оставить заявку</button>
              </div>
            </div>
            <div className={cl.firstPack}>
              <div className={cl.h3Andh4}>
                <h3>Оптимальный</h3>
              </div>
              <div className={cl.imgServ}>
                <img src={optimalpack} className={cl.serviceImg} alt="Оптимальный набор охранной сигнализации" />
              </div>
              <div className={cl.pServ}>
                <p>8-ми шлейфовой прибор. Канал связи GSM. Комплект датчиков объема, датчиков разбития и СМК для всех зон на объекте</p>
              </div>
              <div className={cl.priceAndBtn}>
                <h4>от 14900 руб</h4>
                <button type="button" className={cl.requestButton} onClick={() => { setVisibleModalServices(true); setService('Охранная сигнализация (Оптимальный комплект)'); setPrice('от 14900 руб'); }}>Оставить заявку</button>
              </div>
            </div>
            <div className={cl.firstPack}>
              <div className={cl.h3Andh4}>
                <h3>Максимальный</h3>
              </div>
              <div className={cl.imgServ}>
                <img src={maxpack} className={cl.serviceImg} alt="Максимальный набор охранной сигнализации" />
              </div>
              <div className={cl.pServ}>
                <p>8-ми шлейфовой прибор. Канал связи GSM. Комплект датчиков объема, датчиков разбития и СМК для всех зон на объекте. Обеспечение бесперебойного питания</p>
              </div>
              <div className={cl.priceAndBtn}>
                <h4>от 18900 руб</h4>
                <button type="button" className={cl.requestButton} onClick={() => { setVisibleModalServices(true); setService('Охранная сигнализация (Максимальный комплект)'); setPrice('от 18900 руб'); }}>Оставить заявку</button>
              </div>
            </div>
          </>
        )}

      </div>

    </div>
  );
}
