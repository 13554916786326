import React from 'react';
import cl from './StyleComp.module.css';
// import sb026 from '../img/kop026.png';
// import svg026 from '../svg/kop026.svg';

export default function AboutSecuritySystems() {
  return (
    <div className={cl.AboutSecurityBlock}>
      {/* <div className={cl.LeftInfo}>
        <h3>
          ПРЕИМУЩЕСТВА
        </h3>
        <h4>современных охранных систем</h4>

      </div> */}

      <div className={cl.RightInfo}>
        <h3>
          ПРЕИМУЩЕСТВА
        </h3>
        <h4>современных охранных систем</h4>
        <ul>
          <li>Вам доступна история кто и когда ставил/снимал объект под охрану</li>
          <li>Техподдержка 24/7</li>
          <li>Эстетичный внешний вид</li>
          <li>Беспроводные датчики - для помещений с ремонтом</li>
          <li>Возможность длительной автономной работы</li>
          <li>Управления со смартфона и через мессенджеры</li>
          <li>Возможность подключения системы видеофиксации тревог</li>

        </ul>
      </div>
      <div className={cl.CentralInfo}>
        {/* <img src={svg026} className={cl.kop026img} alt="КОП-02.6 Стандарт Безопасности" /> */}
        <svg viewBox="0 0 192.59583 148.869" className={cl.kop026img} version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g>
            <circle className={cl.svgTest} cx="75.951022" cy="53.741062" r="6.5" />
            <circle className={cl.svgTest} cx="95.999702" cy="53.741062" r="6.5" />
            <circle className={cl.svgTest} cx="115.97059" cy="53.741062" r="6.5" />
            <circle className={cl.svgTest} cx="75.951022" cy="68.206787" r="6.5" />
            <circle className={cl.svgTest} cx="95.999702" cy="68.206787" r="6.5" />
            <circle className={cl.svgTest} cx="115.97059" cy="68.206787" r="6.5" />
            <circle className={cl.svgTest} cx="75.951022" cy="82.272523" r="6.5" />
            <circle className={cl.svgTest} cx="95.999702" cy="82.272523" r="6.5" />
            <circle className={cl.svgTest} cx="115.97059" cy="82.272523" r="6.5" />
            <circle className={cl.svgTest} cx="95.999702" cy="96.53825" r="6.5" />

            <circle className={cl.svgTest} cx="50.33058" cy="55.51318" r="6.1" />
            <circle className={cl.svgTest} cx="50.33058" cy="68.790094" r="6.1" />
            <circle className={cl.svgTest} cx="50.33058" cy="81.867012" r="6.1" />
            <circle className={cl.svgTest} cx="50.33058" cy="94.90503" r="6.1" />

            <circle className={cl.svgTest} cx="142.05214" cy="68.045685" r="6.5" />
            <circle className={cl.svgTest} cx="142.05214" cy="82.272523" r="6.5" />

            <animate
              attributeName="opacity"
              dur="1s"
              values="0.5;1;0.5"
              repeatCount="indefinite"
            />
          </g>
        </svg>
      </div>
    </div>
  );
}
