import React, { useContext } from 'react';
import { MainContext } from '../../../../context/Main.context';
import cl from './StyleComp.module.css';

export default function UnderHeaderSecurity() {
  const {
    open, open2, open3, open4,
  } = useContext(MainContext);
  return (
    <div className={cl.UnderHeaderSecurity}>
      <div className={open || open2 || open3 || open4 ? `${cl.blur}` : `${cl.invisible} `}>
        <h1>УСТАНОВКА И ОБСЛУЖИВАНИЕ ОХРАННОЙ СИГНАЛИЗАЦИИ</h1>
      </div>
      <h1 className={open || open2 || open3 || open4 ? `${cl.invisible}` : null}>УСТАНОВКА И ОБСЛУЖИВАНИЕ ОХРАННОЙ СИГНАЛИЗАЦИИ</h1>
    </div>
  );
}
