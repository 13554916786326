import React from 'react';
import { Helmet } from 'react-helmet';
import BriefDescriptionSkud from './compoents/BriefDescriptionSkud';
import ServicesSkud from './compoents/ServicesSkud';
import UnderHeaderSkud from './compoents/UnderHeaderSkud';

export default function Skud() {
  return (
    <div id="skudpage">
      <Helmet>
        <meta charSet="utf-8" />
        <title>СТАНДАРТ БЕЗОПАСНОСТИ СКУД</title>
        <meta name="keywords" content="Стандарт безопасности, стандарт безопасности сыктывкар, установка СКУД, контроль доступа, домофон, шлагбаумы, установка шлагбаума, ..." />
        <meta
          name="description"
          content="Проектирование, установка и обслуживание систем контроля доступа"
        />
      </Helmet>
      <UnderHeaderSkud />
      <BriefDescriptionSkud />
      <ServicesSkud />
    </div>
  );
}
