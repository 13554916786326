import React from 'react';
import { Helmet } from 'react-helmet';
import AboutSecuritySystems from './components/AboutSecuritySystems';
import InformationSecurity from './components/InformationSecurity';
import PartnershipStages from './components/PartnershipStages';
import ServicesAndPrices from './components/ServicesAndPrices';
import UnderHeaderSecurity from './components/UnderHeaderSecurity';
import cl from './Security.module.css';

export default function Security() {
  return (
    <div id="securitypage" className={cl.securityComp}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>СТАНДАРТ БЕЗОПАСНОСТИ Охранная сигнализация</title>
        <meta name="keywords" content="Стандарт безопасности, стандарт безопасности сыктывкар, установка охранной сигнализации, установка видеонаблюдения, охранная сигнализация, ЧОП, охрана, ..." />
        <meta
          name="description"
          content="Установка и обслуживание охранных систем от 7990 руб"
        />
      </Helmet>
      <UnderHeaderSecurity />
      <AboutSecuritySystems />
      <InformationSecurity />
      <ServicesAndPrices />
      <PartnershipStages />
    </div>
  );
}
