/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-curly-brace-presence */
import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import cl from './FlyoutNavBar2.module.css';
import user from '../../../../Main/Main/Benefits/img/skudSbkomi.png';
import skudIcon from './svg/skudMenuIcon.svg';
import securityIcon from './svg/securityMenuIcon.svg';
import fireIcon from './svg/fireIcon.svg';
import videoMenuIcon from './svg/videoMenuIcon.svg';
import alarmButtonMenuIcon from './svg/alarmButtonMenuIcon.svg';
import DropdownItem2 from './DropdownItem2';
import { MainContext } from '../../../../../context/Main.context';

export default function FlyoutNavBar2() {
  const { open2, setOpen2 } = useContext(MainContext);
  const navigate = useNavigate();
  return (
    <div className={open2 ? `${cl.dropdownMenu} ${cl.active}` : `${cl.dropdownMenu} ${cl.inactive}`}>
      <h3 className={cl.upperH3}>
        Системы безопасности
        <br />
        <span>Монтаж и обслуживание</span>
      </h3>
      <ul className={cl.ulList}>

        <Link to="/security" className={cl.link} onClick={() => { setOpen2(false); }}><DropdownItem2 img={securityIcon} text={'Охранная сигнализация'} /></Link>
        <Link to="/firesecurity" className={cl.link} onClick={() => { setOpen2(false); }}><DropdownItem2 img={fireIcon} text={'Пожарная сигнализация'} /></Link>
        <Link to="/alarm" className={cl.link} onClick={() => { setOpen2(false); }}><DropdownItem2 img={alarmButtonMenuIcon} text={'Тревожная сигнализация'} /></Link>
        <Link to="/video" className={cl.link} onClick={() => { setOpen2(false); }}><DropdownItem2 img={videoMenuIcon} text={'Видеонаблюдение'} /></Link>
        <Link to="/skud" className={cl.link} onClick={() => { setOpen2(false); }}><DropdownItem2 img={skudIcon} text={'СКУД'} /></Link>

      </ul>
    </div>
  );
}
