/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { useNavigate } from 'react-router-dom';
// import { MainContext } from '../../../../../context/Main.context';
import cl from './FlyoutNavBar2.module.css';

export default function DropdownItem2(props) {
  const { navigate } = useNavigate();
  //   const { open2, setOpen2 } = useContext(MainContext);
  console.log(props);
  return (
    <li className={cl.dropdownItem} onClick={() => { navigate('/security'); }}>
      <img src={props.img} alt="chtoto" />
      <p>{props.text}</p>
    </li>
  );
}
