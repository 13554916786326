/* eslint-disable no-unused-vars */
import React, { useContext, useState } from 'react';
// import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
// import { Carousel } from 'react-responsive-carousel';
import cl from './StyleComp.module.css';
import zero from '../svg/choose_0.svg';
import first from '../svg/sendRequest_1.svg';
import second from '../svg/buildeinCheck_2.svg';
import third from '../svg/priceCount_3.svg';
import fourth from '../svg/dogovor_4.svg';
import fifth from '../svg/trackStatus_5.svg';
import sixth from '../svg/actOfFinishedWorks_6.svg';
import seventh from '../svg/readySystem_7.svg';
// import { UserAccountContext } from '../../../../context/UserAccount.context';
import { MainContext } from '../../../../context/Main.context';

const firstSt = { title: 'Выбор услуги', text: 'Выбрать услугу можно на сайте' };
const secondSt = { title: 'Заявка', text: 'Оставить заявку вы можете на сайте или по телефону 8(8212)-302-409' };
const thirdSt = { title: 'Осмотр объекта', text: 'После подтверждения услуги к вам приедет наш мастер, осмотрит объект и составит акт технической укрепленности объекта' };
const fourthSt = { title: 'Составление сметы', text: 'После осмотра, на основе акта технической укрепленности объекта и пожеланий заказчика, наш инженер начинает поиск подходящего оборудования и составит смету' };
const fifthSt = { title: 'Заключение договора', text: 'После согласования сметы, подписывается договор, делается предоплата за работу и вам выдается доступ в личный кабинет для отслеживания статуса выполнения работы' };
const sixthSt = { title: 'Отслеживание статуса', text: 'Мы приступаем к монтажным работам. Статус выполнения можно отследить в личном кабинете' };
const seventhSt = { title: 'Акт выполненных работ', text: 'После завершения монтажных и пусконаладочных работ, подписывается акт выполненных раюот и акт ввода в эксплуатацию. Оплачивается оставшаяся часть работ' };
const eightSt = { title: 'Готово!', text: 'Ваша система безопасности готова! Вы можете оставить отзыв о нашей работе' };
export default function PartnershipStages() {
  const [stageInfo, setStageInfo] = useState();
  const { visible, setVisible } = useContext(MainContext);

  function setStageInfoFunc(e) {
    switch (e.target.id) {
      case 'firstone':
        return (setStageInfo(firstSt));
      case 'secondone':
        return (setStageInfo(secondSt));
      case 'thirdone':
        return (setStageInfo(thirdSt));
      case 'fourthone':
        return (setStageInfo(fourthSt));
      case 'fifthone':
        return (setStageInfo(fifthSt));
      case 'sixthone':
        return (setStageInfo(sixthSt));
      case 'seventhone':
        return (setStageInfo(seventhSt));
      case 'eightone':
        return (setStageInfo(eightSt));
      default: return null;
    }
  }
  console.log(stageInfo, 'Стейджинфо');
  return (

    <div className={cl.PartnershipBox}>
      <h2>ЭТАПЫ ВЗАИМОДЕЙСТВИЯ</h2>
      <div className={cl.PartnershipStagesBox}>
        <div className={cl.stagebox}>
          <p>Выбор услуги</p>
          <img src={zero} className={`${stageInfo?.title}` === 'Выбор услуги' ? (cl.stageChosen) : (cl.stage)} id="firstone" alt="Выбрать услугу" onClick={(e) => setStageInfoFunc(e)} />
        </div>
        <div className={cl.stagebox}>
          <p>Заявка</p>
          <img src={first} className={`${stageInfo?.title}` === 'Заявка' ? (cl.stageChosen) : (cl.stage)} id="secondone" alt="Отправить заявку" onClick={(e) => setStageInfoFunc(e)} />
        </div>
        <div className={cl.stagebox}>
          <p>Осмотр объекта</p>
          <img src={second} className={`${stageInfo?.title}` === 'Осмотр объекта' ? (cl.stageChosen) : (cl.stage)} id="thirdone" alt="Осмотр объекта" onClick={(e) => setStageInfoFunc(e)} />
        </div>
        <div className={cl.stagebox}>
          <p>Смета</p>
          <img src={third} className={`${stageInfo?.title}` === 'Составление сметы' ? (cl.stageChosen) : (cl.stage)} id="fourthone" alt="Составление сметы" onClick={(e) => setStageInfoFunc(e)} />
        </div>
        <div className={cl.stagebox}>
          <p>Договор</p>
          <img src={fourth} className={`${stageInfo?.title}` === 'Заключение договора' ? (cl.stageChosen) : (cl.stage)} id="fifthone" alt="Подписание договора" onClick={(e) => setStageInfoFunc(e)} />
        </div>
        <div className={cl.stagebox}>
          <p>Монтаж</p>
          <img src={fifth} className={`${stageInfo?.title}` === 'Отслеживание статуса' ? (cl.stageChosen) : (cl.stage)} id="sixthone" alt="Отслеживание статуса" onClick={(e) => setStageInfoFunc(e)} />
        </div>
        <div className={cl.stagebox}>
          <p>Акт выполненных работ</p>
          <img src={sixth} className={`${stageInfo?.title}` === 'Акт выполненных работ' ? (cl.stageChosen) : (cl.stage)} id="seventhone" alt="Акт выполненных работ" onClick={(e) => setStageInfoFunc(e)} />
        </div>
        <div className={cl.stagebox}>
          <p>Готово!</p>
          <img src={seventh} className={`${stageInfo?.title}` === 'Готово!' ? (cl.stageChosen) : (cl.stage)} id="eightone" alt="Готово!" onClick={(e) => setStageInfoFunc(e)} />
        </div>
      </div>
      <div className={stageInfo ? `${cl.aboutStage}` : `${cl.emptyAboutStage}`}>
        {stageInfo ? (
          <>
            <h4>{stageInfo.title}</h4>
            <h5>{stageInfo.text}</h5>
            {stageInfo?.title === 'Отслеживание статуса' ? (
              <button className={cl.feedBackBtn} type="button" onClick={() => setVisible(true)}>Войти в личный кабинет</button>
            ) : (
              null
            )}
            {stageInfo?.title === 'Готово!' ? (
              <div className={cl.twoButtons}>
                <button
                  className={cl.feedBackBtn}
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    window.open('https://yandex.ru/profile/1016661003');
                  }}
                >
                  Яндекс

                </button>
                <button
                  className={cl.feedBackBtn}
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    window.open('https://g.page/r/CR15_QFlNbVxEBM/review');
                  }}
                >
                  Google

                </button>

              </div>
            ) : (
              null
            )}
          </>
        ) : (
          <p>Выберите этап</p>
        )}

      </div>
    </div>
  );
}
